import { Pipe, PipeTransform } from '@angular/core';
import {TimeDifferenceService} from "../services/time-difference.service";


@Pipe({
  name: 'timeDifference'
})
export class TimeDifferencePipe implements PipeTransform {
  constructor(private timeService: TimeDifferenceService) {
  }


  transform(value: any) {
    return this.timeService.getTimeDifferenceString(value)
  }

}
