<router-outlet></router-outlet>
<p-toast position="top-center"></p-toast>


<!--<div class="card">-->
<!--  &lt;!&ndash; begin::Body&ndash;&gt;-->
<!--  <div class="card-body">-->
<!--    &lt;!&ndash; begin::Wrapper&ndash;&gt;-->
<!--    <div class="mw-lg-950px mx-auto w-100">-->
<!--      &lt;!&ndash; begin::Header&ndash;&gt;-->
<!--      <div class="d-flex justify-content-between flex-column flex-sm-row mb-19">-->
<!--        <img alt="Logo" src="/assets/media/logos/banner.png" class="w-100">-->
<!--      </div>-->
<!--      &lt;!&ndash;end::Header&ndash;&gt;-->

<!--      &lt;!&ndash;begin::Body&ndash;&gt;-->
<!--      <div class="pb-12">-->
<!--        &lt;!&ndash;begin::Wrapper&ndash;&gt;-->
<!--        <div class="d-flex flex-column gap-7 gap-md-10">-->
<!--          &lt;!&ndash;begin::Message&ndash;&gt;-->
<!--          <div class="fw-bold fs-2 d-flex flex-row justify-content-between">-->
<!--            <div>-->
<!--              To Johnson Maree <span class="fs-6"> - richard@maree.com</span> <br>-->
<!--              <span class="text-gray-700 fs-5">from Ilia Motruk - iliamotruk@ardiexpress.com</span>-->
<!--            </div>-->

<!--            <div  style="min-width:250px" class=" d-flex flex-column align-items-end justify-content-end">-->
<!--              <span>Offer LOG25004567</span>-->
<!--              <span class="text-gray-700 fs-5">02 September, 2024</span>-->
<!--            </div>-->

<!--          </div>-->
<!--          &lt;!&ndash;begin::Message&ndash;&gt;-->

<!--          &lt;!&ndash;begin::Separator&ndash;&gt;-->
<!--          <div class="separator"></div>-->
<!--          &lt;!&ndash;begin::Separator&ndash;&gt;-->



<!--          &lt;!&ndash;begin:Order summary&ndash;&gt;-->
<!--          <div class="d-flex justify-content-between flex-row">-->
<!--            &lt;!&ndash;begin::Table&ndash;&gt;-->
<!--            <div class="table-responsive border-bottom mb-9">-->
<!--              <table class="table align-middle table-row-dashed fs-6 gy-5 mb-0">-->
<!--                <thead>-->
<!--                <tr class="border-bottom fs-6 fw-bold text-muted">-->
<!--                  <th class="min-w-245px pb-2">Shipping methods:</th>-->
<!--                </tr>-->
<!--                </thead>-->

<!--                <tbody class="fw-semibold text-gray-600">-->
<!--                <tr>-->
<!--                  <td>-->
<!--                    <div class="d-flex align-items-center">-->
<!--                      &lt;!&ndash;begin::Thumbnail&ndash;&gt;-->
<!--                      &lt;!&ndash;end::Thumbnail&ndash;&gt;-->

<!--                      &lt;!&ndash;begin::Title&ndash;&gt;-->
<!--                      <div class="ms-5">-->
<!--                        <div class="fs-2 text-gray-900 fw-bold">-->
<!--                          1)REGULAR OCEAN FREIGHT + TRUCK-->

<!--                        </div>-->
<!--                        <div class="fs-7 fs-3 text-gray-700 fw-bold">TIME : 35-45 WORKING DAYS FROM THE DATE OF DEPARTURE <br>-->
<!--                          (IN CASE WITHOUT RANDOMLY INSPECTION)</div>-->
<!--                        <div class="fs-2 text-gray-900 fw-bold">-->
<!--                          TOTAL PRICE DDP : 700 USD-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      &lt;!&ndash;end::Title&ndash;&gt;-->
<!--                    </div>-->
<!--                  </td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                  <td>-->
<!--                    <div class="d-flex align-items-center">-->
<!--                      &lt;!&ndash;begin::Thumbnail&ndash;&gt;-->
<!--                      &lt;!&ndash;end::Thumbnail&ndash;&gt;-->

<!--                      &lt;!&ndash;begin::Title&ndash;&gt;-->
<!--                      <div class="ms-5">-->
<!--                        <div class="fs-2 text-gray-900 fw-bold">-->
<!--                          2)FAST MATSON OCEAN FREIGHT + UPS-->

<!--                        </div>-->
<!--                        <div class="fs-7 fs-3 text-gray-700 fw-bold">TIME : 18-25 WORKING DAYS FROM THE DATE OF DEPARTURE <br>-->
<!--                          (IN CASE WITHOUT RANDOMLY INSPECTION)</div>-->
<!--                        <div class="fs-2 text-gray-900 fw-bold">-->
<!--                          TOTAL PRICE DDP : 1400 USD-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      &lt;!&ndash;end::Title&ndash;&gt;-->
<!--                    </div>-->
<!--                  </td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                  <td>-->
<!--                    <div class="d-flex align-items-center">-->
<!--                      &lt;!&ndash;begin::Thumbnail&ndash;&gt;-->
<!--                      &lt;!&ndash;end::Thumbnail&ndash;&gt;-->

<!--                      &lt;!&ndash;begin::Title&ndash;&gt;-->
<!--                      <div class="ms-5">-->
<!--                        <div class="fs-2 text-gray-900 fw-bold">-->
<!--                          3)AIR FREIGHT + UPS-->

<!--                        </div>-->
<!--                        <div class="fs-7 fs-3 text-gray-700 fw-bold">TIME : 8-12 WORKING DAYS FROM THE DATE OF DEPARTURE <br>-->
<!--                          (IN CASE WITHOUT RANDOMLY INSPECTION)</div>-->
<!--                        <div class="fs-2 text-gray-900 fw-bold">-->
<!--                          TOTAL PRICE DDP : 5600 USD-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      &lt;!&ndash;end::Title&ndash;&gt;-->
<!--                    </div>-->
<!--                  </td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                  <td>-->
<!--                    <div class="d-flex align-items-center">-->
<!--                      &lt;!&ndash;begin::Thumbnail&ndash;&gt;-->
<!--                      &lt;!&ndash;end::Thumbnail&ndash;&gt;-->

<!--                      &lt;!&ndash;begin::Title&ndash;&gt;-->
<!--                      <div class="ms-5">-->
<!--                        <div class="fs-3 text-gray-900 fw-bold">-->
<!--                          PICK UP : 70 USD-->

<!--                        </div>-->
<!--                      </div>-->
<!--                      &lt;!&ndash;end::Title&ndash;&gt;-->
<!--                    </div>-->
<!--                  </td>-->
<!--                </tr>-->
<!--                </tbody>-->
<!--              </table>-->
<!--            </div>-->

<!--            <div style="width:30%">-->
<!--              <div class="d-flex flex-column flex-sm-row gap-7 gap-md-10 fw-normal mt-5">-->
<!--                <div class="flex-root d-flex flex-column">-->
<!--                  <span class="text-muted fs-6 fw-bold text-muted mb-2">Shipment details</span>-->
<!--                  <div class="separator mb-3"></div>-->
<!--                  <span class="fs-5 text-gray-900" style="line-height: 1.8;">-->
<!--                      <span><span class="fw-bold">Destination:</span> Ardi Express 1590 Dan Kipper Drive Riverside, CA 92507</span> <br>-->
<!--                      <span ><span class="fw-bold">Product:</span> Modern Purr- Cat Litter Refill Bags</span> <br>-->
<!--                      <span><span class="fw-bold">Total boxes:</span> 42 </span> <br>-->
<!--                      <span><span class="fw-bold">Total weight:</span> 848.4</span> <br>-->
<!--                      <span><span class="fw-bold">Total volume:</span> 3.486</span> <br>-->
<!--                      <span><span class="fw-bold">Service:</span> DDP - Duty Paid</span> <br>-->
<!--                    </span>-->
<!--                </div>-->

<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;end::Table&ndash;&gt;-->
<!--          </div>-->
<!--          &lt;!&ndash;end:Order summary&ndash;&gt;-->
<!--        </div>-->
<!--        &lt;!&ndash;end::Wrapper&ndash;&gt;-->
<!--      </div>-->
<!--      &lt;!&ndash;end::Body&ndash;&gt;-->

<!--      &lt;!&ndash; begin::Footer&ndash;&gt;-->
<!--      ★ Optional insurance = 3% of the value of the consignment. (Compensation is paid for lost boxes. The cost of compensation is 100% of the value of the consignment according to the invoice. The approximate time for compensation is 35-45 days).-->

<!--      <br><br>-->

<!--      ★ Payment for delivery is made after receiving the goods at our warehouse in China and taking measurements of the goods.-->

<!--      <br><br>-->

<!--      ★ Prices include duties, taxes, and fees. No additional hidden charges. The price is not changed in case of a customs check in the USA. The price does not include additional cargo insurance.-->

<!--      <br><br>-->

<!--      ★ (When paying by credit card / Payoneer + 1% fee)-->

<!--      <br><br>-->

<!--      ★ DDU - Duties and customs fees are not included.<br> ★ DDP - Payment of duty and customs duty included.<br>-->

<!--      <br>-->
<!--      ★ Due to COVID-19 and situations in the port, there may be delays.-->

<!--      <br><br>-->

<!--      ★ Estimated delivery times are given without accidental checking or delay in ports.-->

<!--      <br><br>-->

<!--      ★ The price is valid for a week.-->

<!--      &lt;!&ndash; end::Footer&ndash;&gt;-->
<!--    </div>-->
<!--    &lt;!&ndash; end::Wrapper&ndash;&gt;-->
<!--  </div>-->
<!--  &lt;!&ndash; end::Body&ndash;&gt;-->
<!--</div>-->










<!--<div class="card">-->
<!--  &lt;!&ndash; begin::Body&ndash;&gt;-->
<!--  <div class="card-body py-20">-->
<!--    &lt;!&ndash; begin::Wrapper&ndash;&gt;-->
<!--    <div class="mw-lg-950px mx-auto w-100">-->
<!--      &lt;!&ndash; begin::Header&ndash;&gt;-->
<!--      <div class="d-flex justify-content-between flex-column flex-sm-row mb-19">-->
<!--        <img alt="Logo" src="/assets/media/logos/banner.png" class="w-100">-->
<!--      </div>-->
<!--      &lt;!&ndash;end::Header&ndash;&gt;-->

<!--      &lt;!&ndash;begin::Body&ndash;&gt;-->
<!--      <div class="pb-12">-->
<!--        &lt;!&ndash;begin::Wrapper&ndash;&gt;-->
<!--        <div class="d-flex flex-column gap-7 gap-md-10">-->
<!--          &lt;!&ndash;begin::Message&ndash;&gt;-->
<!--          &lt;!&ndash;begin::Separator&ndash;&gt;-->

<!--          &lt;!&ndash;begin::Order details&ndash;&gt;-->
<!--          <div class="d-flex flex-column flex-sm-row gap-7 gap-md-10 fw-bold">-->
<!--            <div class="flex-root d-flex flex-column">-->
<!--              <div class="flex-root d-flex flex-column">-->
<!--                <span class="text-muted mb-2" style="min-width:300px">Billing Address</span>-->
<!--                <span class="fs-4">-->
<!--                                Maree LLC <br>-->
<!--                                Unit 1/23 Hastings Road,<br>-->
<!--                                Melbourne 3000,Australia.-->

<!--                            </span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="flex-root d-flex flex-column"></div>-->
<!--            <div class="flex-root d-flex flex-column"></div>-->


<!--            <div class="flex-root d-flex flex-column fs-2 align-items-end" style="min-width:250px">-->
<!--              <span>Invoice LOG25004567</span>-->
<!--              <span>Schedule B</span>-->
<!--              <span class="text-muted fs-5">02 September, 2024</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          &lt;!&ndash;end::Order details&ndash;&gt;-->

<!--          &lt;!&ndash;begin::Billing & shipping&ndash;&gt;-->

<!--          <span class="fs-4 fw-bold">FREIGHT MANAGEMENT/TRANSPORTATION LOGISTICS SERVICES </span>-->
<!--          <span class="fs-4" style="line-height: 1.8;">-->

<!-- - Planning and scheduling shipments <br>-->
<!-- - shipment processing, preparing shipping documents and invoices<br>-->
<!-- - pick up at warehouse Shenzhen – Port Shenzhen<br>-->
<!-- - broker fee Shenzhen<br>-->
<!-- - port fee Shenzhen<br>-->
<!-- - sea freight Shenzhen – LA <br>-->
<!-- - port fee USA<br>-->
<!-- - broker fee USA<br>-->
<!-- - pick up port LA -  <br>-->
<!--            <br>-->
<!--             Shipping term: DDP-->
<!--            <br>-->
<!--             Total boxes/weight: 34 boxes/430 KG-->

<!--                            </span>-->

<!--          &lt;!&ndash;begin:Order summary&ndash;&gt;-->
<!--          <div class="d-flex justify-content-between flex-column">-->
<!--            &lt;!&ndash;begin::Table&ndash;&gt;-->
<!--            <div class="table-responsive border-bottom mb-9">-->
<!--              <table class="table align-middle table-row-dashed fs-6 gy-3 mb-0">-->
<!--                <thead>-->
<!--                <tr class="border-bottom fs-6 fw-bold text-muted">-->
<!--                  <th class="min-w-245px pb-2">Services</th>-->
<!--                  <th class="min-w-80px text-end pb-2"></th>-->
<!--                  <th class="min-w-100px text-end pb-2">Total</th>-->
<!--                </tr>-->
<!--                </thead>-->

<!--                <tbody class="fw-semibold ">-->
<!--                <tr>-->
<!--                  <td>-->
<!--                    <div class="d-flex align-items-center">-->
<!--                      &lt;!&ndash;begin::Thumbnail&ndash;&gt;-->
<!--                      &lt;!&ndash;end::Thumbnail&ndash;&gt;-->

<!--                      &lt;!&ndash;begin::Title&ndash;&gt;-->
<!--                      <div class="ms-5">-->
<!--                        <div class="fw-bold">Freight</div>-->
<!--                        <div class="fs-7 text-muted">Fast matson freight + UPS</div>-->
<!--                      </div>-->
<!--                      &lt;!&ndash;end::Title&ndash;&gt;-->
<!--                    </div>-->
<!--                  </td>-->
<!--                  <td>-->
<!--                  </td>-->
<!--                  <td class="text-end">-->
<!--                    $240.00-->
<!--                  </td>-->
<!--                </tr>-->

<!--                <tr>-->
<!--                  <td>-->
<!--                    <div class="d-flex align-items-center">-->
<!--                      &lt;!&ndash;begin::Thumbnail&ndash;&gt;-->
<!--                      &lt;!&ndash;end::Thumbnail&ndash;&gt;-->

<!--                      &lt;!&ndash;begin::Title&ndash;&gt;-->
<!--                      <div class="ms-5">-->
<!--                        <div class="fw-bold">Insurance</div>-->
<!--                        <div class="fs-7 text-muted">Invoice value: 15000 USD</div>-->
<!--                      </div>-->
<!--                      &lt;!&ndash;end::Title&ndash;&gt;-->
<!--                    </div>-->
<!--                  </td>-->

<!--                  <td class="text-end">-->
<!--                  </td>-->
<!--                  <td class="text-end">-->
<!--                    $24.00-->
<!--                  </td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                  <td colspan="2" class="text-end">-->
<!--                    Payonner fee-->
<!--                  </td>-->
<!--                  <td class="text-end">-->
<!--                    $2.64-->
<!--                  </td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                  <td colspan="2" class="fs-3 text-gray-900 fw-bold text-end">-->
<!--                    Grand Total-->
<!--                  </td>-->
<!--                  <td class="text-gray-900 fs-3 fw-bolder text-end">-->
<!--                    $266.64-->
<!--                  </td>-->
<!--                </tr>-->
<!--                </tbody>-->
<!--              </table>-->
<!--            </div>-->
<!--            &lt;!&ndash;end::Table&ndash;&gt;-->
<!--          </div>-->
<!--          &lt;!&ndash;end:Order summary&ndash;&gt;-->
<!--        </div>-->
<!--        &lt;!&ndash;end::Wrapper&ndash;&gt;-->
<!--      </div>-->
<!--      &lt;!&ndash;end::Body&ndash;&gt;-->

<!--      &lt;!&ndash; begin::Footer&ndash;&gt;-->
<!--      <br><br>-->
<!--      <b>* Footnote</b> <br>-->
<!--      Client shall pay the invoice from manager within 3 business days from the date of the invoice. <br>-->
<!--      All invoices not paid by the due date will be subject to a late charge of:<br>-->
<!--      3% - payment for 4-7 working days<br>-->
<!--      8% - payment 8-14 working days<br>-->
<!--      12% - payment 15-25 working days<br>-->
<!--      15% - payment 25 - 40 working days<br>-->
<!--      Disposal of goods - no payment for more than 40 days.<br>-->

<!--      <br>-->
<!--      <b>Payment details:</b> <br>-->
<!--      Company name: ARDI LOGISTICS LLC<br>-->
<!--      Account number: 898136351576<br>-->
<!--      Routing number: 063100277<br>-->
<!--      Routing number for wire: 026009593<br>-->
<!--      SWIFT: BOFAUS3N<br>-->
<!--      Company address : 17201 Collins Avenue, Oceans Four # 3402, Sunny Isles Beach, FL 33160, US<br>-->
<!--      <br>-->
<!--      This routing number 063100277 can only be used for direct deposits and ACH transactions. For wire transfers, please use routing number 026009593-->
<!--      <br><br>-->
<!--      Or-->
<!--      <br><br>-->
<!--      <b>Payoneer account:</b> payment@ardilogistics.com<br>-->
<!--      <b>Zelle account:</b> tovstolisigor7@gmail.com-->




<!--      &lt;!&ndash; end::Footer&ndash;&gt;-->
<!--    </div>-->
<!--    &lt;!&ndash; end::Wrapper&ndash;&gt;-->
<!--  </div>-->
<!--  &lt;!&ndash; end::Body&ndash;&gt;-->
<!--</div>-->
