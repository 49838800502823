<div id="kt_deals_block" class="pe-4" style="max-height: 460px;overflow: auto;">
  <!--begin::Time-->
  <div class="d-flex flex-stack position-relative mb-8" *ngFor="let deal of deals">
    <a routerLink="/deals/{{deal.id}}" routerLinkActive="active" class="w-lg-100 d-block bg-hover-light-primary p-2 deal-link">
      <!--begin::Bar-->
        <div class="position-absolute h-100 w-4px {{deal.deal_types_id === 1 ? (settingsData|getClientOption:'deal_statuses':deal.deal_statuses_id:true) : (settingsData|getClientOption:'deal_warehouse_statuses':deal.deal_statuses_id:true)}} rounded top-0 start-0"></div>
      <!--end::Bar-->
      <!--begin::Info-->
      <div class="fw-bold ms-5 text-gray-600 d-flex justify-content-between align-items-center">
        <!--begin::Time-->
        <div class="pe-5">
          <!--begin::Title-->
          <a class="fs-5 fw-bolder text-gray-800 text-hover-primary mb-2 d-block">{{deal.name ? deal.name : 'Сделка #'+ deal.id}}</a>
          <!--end::Title-->
          <!--begin::User-->
          <div class="text-gray-400">
            <span class="badge badge-light me-2">{{settingsData|getClientOption:'deal_types':deal.deal_types_id}}</span>
            <span
              class="badge-custom {{deal.deal_types_id === 1 ? (settingsData|getClientOption:'deal_statuses':deal.deal_statuses_id:true) : (settingsData|getClientOption:'deal_warehouse_statuses':deal.deal_statuses_id:true)}} me-2">
                 {{deal.deal_types_id === 1 ? (settingsData|getClientOption:'deal_statuses':deal.deal_statuses_id) : (settingsData|getClientOption:'deal_warehouse_statuses':deal.deal_statuses_id)}}</span>
            <span class="text-success">${{deal.budget}}</span>
          </div>
          <!--end::User-->
        </div>
        <div class="fs-5 gap-1 text-end">
          <span class="fs-7 text-gray-400 text-uppercase">{{deal.updated_at | date:'dd.MM.YYYY'}}</span><br>
          <span class="fs-7 text-gray-400 text-uppercase">{{deal.updated_at | date:'HH:mm'}}</span>
        </div>
        <!--end::Time-->

      </div>
      <!--end::Info-->
    </a>
  </div>
  <!--end::Time-->
</div>
