//RU
export const locale = {
  lang: 'ru',
  data: {
    TRANSLATOR: {
      SELECT: 'Выберите свой язык',
    },
    MENU: {
      NEW: 'новый',
      ACTIONS: 'Действия',
      CREATE_POST: 'Создать новый пост',
      PAGES: 'Страницы',
      FEATURES: 'Разработки',
      APPS: 'Приложения',
      DASHBOARD: 'Дашборд',
      CLIENTS: 'Клиенты',
      DEALS: 'Сделки',
      DOCS: 'Документы',
      INVOICES: 'Инвойсы',
      USERS: 'Пользователи',
      ROLES: 'Роли',
      SETTINGS: 'Настройки',
      SETTINGS_SYS: 'Настройки системы',
      SHIPMENTS: 'Отправки',
      STATISTICS: 'Статистика',
      CLIENT_DEALS: 'Мои сделки',
      JOBS: 'Задачи',
      TASKS:'Задачи',
      FILES:'Файлы',
      BOARD: 'Доска',
      TOOLS: {
        TITLE: 'Инструменты',
        INSPECTION_TOOL: 'Калькулятор инспецкции'
      },
    },
    APP_USER_MENU: {
      PROFILE: 'Профиль',
      DEALS: 'Мои сделки',
      LANGUAGE: 'Язык',
      LOGOUT: 'Выйти',
    },
    AUTH: {
      GENERAL: {
        OR: 'Или',
        SUBMIT_BUTTON: 'Отправить',
        NO_ACCOUNT: 'У вас еще нет аккаунта?',
        SIGNUP_BUTTON: 'Регистрация',
        FORGOT_BUTTON: 'Забыли пароль',
        BACK_BUTTON: 'Назад',
        PRIVACY: 'Конфиденциальность',
        LEGAL: 'Права',
        CONTACT: 'Контакты',
      },
      LOGIN: {
        TITLE: 'Вход в систему',
        BUTTON: 'Вход',
      },
      FORGOT: {
        TITLE: 'Забыли пароль?',
        DESC: 'Веедите свой e-mail для сброса пароля',
        SUCCESS: 'Ваш аккаунт успешно восстановлен.'
      },
      REGISTER: {
        TITLE: 'Регистрация',
        DESC: 'Введите ваши данные для создания аккаунта',
        SUCCESS: 'Ваш аккаунт успешно зарегистрирован.'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Ваше имя',
        PASSWORD: 'Пароль',
        CONFIRM_PASSWORD: 'Подтвердите пароль',
        USERNAME: 'Имя пользователя'
      },
      VALIDATION: {
        INVALID: '{{name}} не корректное',
        REQUIRED: '{{name}} обязательно',
        MIN_LENGTH: '{{name}} минимальная длинна {{min}}',
        AGREEMENT_REQUIRED: 'Необходимо принять условия',
        NOT_FOUND: 'Запрашивамая {{name}} не найдена',
        INVALID_LOGIN: 'Данные для входа не верные',
        REQUIRED_FIELD: 'Обязательное поле',
        MIN_LENGTH_FIELD: 'Минимальная длинна:',
        MAX_LENGTH_FIELD: 'Масимальная длинна:',
        INVALID_FIELD: 'Поле не корректное',
        INVALID_EMAIL:'Не верный email'
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Количество выбранных записей: ',
        ALL: 'Все',
        SUSPENDED: 'Приостановленный',
        ACTIVE: 'Активный',
        FILTER: 'Фильтр',
        BY_STATUS: 'по Статусу',
        BY_TYPE: 'По типу',
        BUSINESS: 'Бизнесс',
        INDIVIDUAL: 'Индивидуальный',
        SEARCH: 'Поиск',
        IN_ALL_FIELDS: 'во всех полях'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Клиенты',
        CUSTOMERS_LIST: 'Список клиентов',
        NEW_CUSTOMER: 'Новый клиент',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Удаление клиента',
          DESCRIPTION: 'Вы уверены что мгновенно хотите удалить клиента?',
          WAIT_DESCRIPTION: 'Удаление клиента...',
          MESSAGE: 'Клиент удален'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Удаление клиентов',
          DESCRIPTION: 'Вы уверены что хотите удалить выбранных клиентов?',
          WAIT_DESCRIPTION: 'Удаление клиентов...',
          MESSAGE: 'Выбранные клиенты удалены'
        },
        UPDATE_STATUS: {
          TITLE: 'Обновлен статус для выбранных клиентов',
          MESSAGE: 'Статус выбранных клиентов успешно обновлен'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Клиент обновлен',
          ADD_MESSAGE: 'Клиент успешно создан'
        }
      }
    },
    CLIENTS: {
      SEARCH_CUSTOMERS: 'Поиск клиентов',
      ADD_CUSTOMER: 'Добавить',
      CUSTOMER_NAME: 'Имя клиента',
      CUSTOMER_EMAIL: 'E-mail',
      CUSTOMER_COMPANY: 'Компания',
      CUSTOMER_PHONE: 'Телефон',
      CUSTOMER_STATUS: 'Статус валидации',
      CUSTOMER_DETAIL: 'Клиент',
      CUSTOMER_INFO_TAB: 'Детали',
      CUSTOMER_DEALS_TAB: 'Сделки',
      CUSTOMER_ACTIVITY_TAB: 'Активность',
      CUSTOMER_ADD_DEAL: 'Сделка',
      CUSTOMER_ADD_JOB: 'Задача',
      QUOTATION:"Расчет",
      CUSTOMER_WORK_REGION: 'Регион',
      CUSTOMER_ADDRESS: 'Штат / Область / Город / Адрес',
      CLIENTS_TAB_SHIPMENTS: 'Отправки',
      CLIENTS_TAB_INFO: 'Инфо',
      TABS_DETAILS:{
        WORK_STATUS: 'Статус работы',
        FB_STATUS: 'Фейсбук статус',
        POSITION: 'Бренд',
        COMPANY: 'Компания',
        REF_PATH: 'Откуда пришел',
        DELIVERY: 'С кем возит',
        PARTNER_FB: 'Facebook партнер',
        MANAGER: 'Менеджер',
        ADVISER: 'Ответственный',
        TELEGRAM:'Телеграм',
      },
      FORM:{
        CLIENT_EDIT_TITLE: 'Добавление / редактирование клиента',
        CLIENT_AVATAR: 'Фото/Аватар',
        CLIENT_F_L_NAME: 'Фамилия Имя',
        CLIENT_ADDITIONAL_PHONE: 'Дополнительный телефон',
        CLIENT_MANAGER: 'Менеджер',
        CLIENT_ADVISER: 'Ответственный',
        CLIENT_AFFILIATE_TYPE: 'Откуда пришел',
        CLIENT_POSITION: 'Бренд',
        CLIENT_ADDITIONAL_INFO: 'Дополнительная информация (любой текст не указанный в полях выше)'
      },
      NO_FILES:'Нет файлов',
      DELETE_CLIENT: {
        TITLE: 'Удалить клиента',
        ATTENTION: 'Внимание!!',
        TEXT: 'Вы уверены что хотите удалить этого клиента?',
      },
    },
    DEALS: {
      SEARCH_DEAL: 'Поиск сделок',
      DEAL_ADD: 'Добавить сделку',
      DEAL_ID_OR_NAME: 'Имя/Номер сделки',
      DEAL_ID: 'Номер сделки',
      DEAL_CLIENT_NAME: 'Клиент',
      DEAL_DIRECTION: 'Направление',
      DEAL_DATE: 'Дата сделки',
      DEAL_TYPE: 'Тип сделки',
      DEAL_STATUS: 'Статус',
      SUM_FROM:'Сумма с',
      SUM_TO:'на',
      QUOTATION:"Запрос",
      CALCULATION:"Расчет",
      RATES:'Ставки',
      COMMENT_PLACEHOLDER: 'Введите данные...',
      PINNED_MESSAGES:'Закрепленное сообщение',
      LEFT:'Осталось',
      TOOLTIP:'Только цифры и . (без пробелов)',
      EXPIRED:'Просрочена',
      MANAGER:'Менеджер',
      RESPONSIBLE:'Ответственный',
      DELIVERY_TYPE:'Тип доставки',
      PICKUP:'Pick up',
      INSPECTOR:'Инспектор',
      BUDGET:'Бюджет',
      CONTRAGENT:'Котрагент',
      CONTRAGENT_SUM:'Сумма котрагента',
      PAYMENT_DATE:'Дата оплаты',
      PAYMENT_TYPE:'Вид оплаты',
      NEW_REGULAR:'Новый/постоянный клиент',
      AFFILIATION_ORIGIN:'Откуда пришел',
      EDIT_DEAL_TITLE:'Редактировать сделку',
      NO_DEALS:'Нет сделок',
      FILTER_OPTIONS:'Опции фильтра',
      CREATED:'Создано',
      INVOICE:'Инвойс',
      AGREEMENT:"Договор",
      NEW_DEAL_MODAL:{
        TITLE:'Новая сделка',
        DEAL_NAME:"Имя сделки"
      },
      DELETE_DEAL_MODAL:{
        TITLE:'Удалить сделку',
        ATTENTION: 'Внимание!!',
        TEXT: 'Вы уверены что хотите удалить эту сделку?',
      },
      DELETE_MODAL: {
        TITLE:'Удалить сообщение',
        ATTENTION: 'Внимание!!',
        TEXT: 'Вы уверены что хотите удалить это сообщение?',
      },
      QUOTATION_MODAL:{
        TITLE:'Новый запрос',
        DEAL_ID:'Номер сделки',
        BOXES_TYPE:'Видов коробок',
        BOXES_DIMENSIONS:'Размеры коробок',
        UNITS:'Единицы',
        BOXES_NUMBER:'Количество коробок',
        BOXES_WEIGHT:'Вес коробок',
        GOODS_NAME:'Название товара',
        VALUE:'Цена',
        PICK_UP_ADDRESS:'Адрес поставщика',
        DELIVERY_ADDRESS:'Адрес доставки'
      },
      INVOICE_MODAL:{
        TITLE:"Создать инвойс",
        DDP_DDU:"DDP/DDU",
        DESTINATION:"Адрес доставки",
        DESTINATION_COUNTRY:"Страна назначения",
        RECIPIENT:"Получатель",
        DELIVERY_METHOD:"Метод отправки",
        BOXES_NUMBER:"Количество коробок",
        FREIGHT:'Фрахт',
        PICKUP:'Pick up',
        INSURANCE:'Страховка',
        INSPECTION:'Инспекция',
        WEIGHT_CBM:"Вес/CBM"
      },
      AGREEMENT_MODAL:{
        TITLE:"Создать договор",
        DATE:"Дата договора"
      },
      CALCULATION_TITLE:'Новый расчет',
      GALLERY:'Галерея',
      GALLERY_NO_PHOTO:'Изображение не найдено'
    },
    SHIPMENTS: {
      SEARCH_PLACEHOLDER: 'Поиск',
      ADD_CONTAINER_TITLE: 'Добавить контейнер',
      ADD_CONTAINER_FORM: 'Добавление контейнера',
      SHIPMENT_TYPE: 'Тип доставки',
      WH_DELIVERY_DATE: 'Дата прибытия на склад',
      CLIENT: 'Клиент',
      TABLE: {
        SHIP_TYPE_TITLE: 'Тип',
        CONTAINER_TITLE: 'Номер контейнера',
        PALLETS_TITLE: 'Кол-во паллет',
        DEPART_TITLE: 'Дата выхода',
        ARRIVE_TITLE: 'Дата прибытия',
        FROM_PLACE_TITLE: 'Место выхода',
        TO_PLACE_TITLE: 'Место прибытия',
        STATUS_TITLE: 'Статус',
        TRACK_NUMBER_TITLE: 'Номер отслеживания',
        CARGO_TITLE: 'Состав груза',
        STORAGE_TITLE: 'Место хранения',
        CONTAINER_NAME: 'Название контейнера',
        DELIVERY_PRICE: 'Цена доставки',
        WH_DELIVERY_DATE: 'Дата прибытия на склад',
        EXPENCES: 'Расходы',
        SHIPMENT_REF_ID: 'Shipment ref ID',
        NOTE: 'Заметки',
        WAREHOUSE_TITLE: 'Хранение',
        DELIVERY_COST_TITLE: 'Затраты доставки',
        UNLOADING_TITLE: 'Разгрузка',
        DRAYAGE_TITLE: 'Транспортировка',
      },
    },
    GENERAL: {
      FILTER: {
        TITLE: 'Опции фильтра',
        BUTTON: 'Фильтр',
        STATUS: 'Статус:',
        MANAGER: 'Менеджер',
        ADVISER: 'Ответственный',
        CLEAR: 'Очистить фильтр',
      },
      CANCEL: 'Отменить',
      SAVE: 'Сохранить',
      YES: 'Да',
      NO: 'Нет',
      PLEASE_WAIT: 'Пожалуйста подождите',
      RESET: 'Сбросить',
      APPLY: 'Применить',
      COMPLETE:'Завершить',
      COMPLETED:'Завершено',
      CREATE:'Создать',
      CLEAR:'Очистить',
      RESULT:'Результат',
      SEND:'Отправить',
      EXPORT: 'Экспорт',
      SEARCH: {
        BUTTON: 'Поиск',
        HELP_MESSAGE: 'Для поиска можно использовать имя, телефон, email, компанию и нажать Enter'
      },
      ACTIONS: 'Действия',
      CHAT: 'Чат',
      MESSAGE: 'Сообщение',
      MESSAGES: 'Сообщения',
      EDIT: 'Редактировать',
      DELETE: 'Удалить',
      DATE:'Дата',
      TIME:'Время',
      DEAL:'Сделка',
      CHANGED:'Изменено',
      YOU:'Вы',
      FILES:'Файлы',
      FILE:'Файл',
      TEXT:'Текст',
      EMAIL:'Почта',
      COMPANY:'Компания',
      SELECT:'Выбрать',
      SELECTED:'Выбрано',
      OUT:'из',
      DISPLAYED:'Отображено',
      RECORDS:'Записей',
      COMMENT:'Комментарий',
      SUM:"Сумма",
      MAIN:"Главная",
      CONFIRM:"Подтвердить"
    },
    JOBS: {
      CURRENT: 'Текущие',
      MISSED: 'Просроченные',
      TODAY: 'Сегодня',
      TOMORROW: 'Завтра',
      AFTER_TOMORROW: 'Послезавтра',
      WEEK: 'Неделя',
      MONTH: 'Месяц',
      YEAR: 'Год',
      NEW_JOB: 'Создать задачу',
      MY_JOBS_CHECK: 'Только мои задачи',
      NOT_CHOSEN:'Не выбрано',
      RESET:'Сбросить',
      FOR_TODAY:'На сегодня',
      FOR_TOMORROW:'На завтра',
      FOR_AFTER_TOMORROW:'На послезавтра',
      FOR_WEEK:'На неделю',
      FOR_MONTH:'На месяц',
      NO_JOBS:'Нет задач',
      MODAL:{
        EDIT_TITLE: 'Редактировать задачу',
        ADD_TITLE: 'Добавить задачу',
        DELETE_TITLE: 'Удалить задачу',
        COMPLETE_TITLE:'Завершить задачу',
        COMMENT: 'Комментарий',
        RESPONSIBLE:'На кого задача',
        JOB_TYPE:'Тип задачи',
        JOB_RESULT:'Результат задачи'
      },
      DELETE_MODAL: {
        CARD_DELETE_ATTENTION: 'Внимание!!',
        CARD_DELETE_TEXT: 'Вы уверенны что хотите удалить задачу?',
      },
    },
    USER:{
      USER:'Пользователь',
      TOOLTIP:"Сбросить пароль и отправить по почте",
      MANAGER:"Менеджер",
      YOUR_MANAGER:"Ваш менеджер",
      DEALS_SUM:"Сумма сделок",
      DEAL_SUM:"Количество сделок",
      DEALS:"Сделки",
      DEAL:"Сделка",
      SETTINGS:"Настройки",
      INFO:"Информация",
      PROFILE_DETAILS:"Детали профиля",
      NAME:"Имя",
      PHONE:"Телефон",
      EMAIL:"Email",
      COMPANY:'Название компании',
      BRAND:"Бренд",
      ADDRESS:"Адресс",
      PROFILE_SETTINGS:"Настройки профиля",
      AVATAR:'Фото',
      FULL_NAME:"Полное имя",
      FILES:"Файлы",
      LOAD_FILES:"Загрузить файлы",
      VESSEL_INFO:"Название судна",
      TRACKING:"Трек номер",
      SHIPMENT_PROGRESS:"Прогресс отправки",
      SHIPMENT_DETAILS:"Детали отправки",
      SECURITY:{
        SECURITY:'Безопасность',
        CHANGE_PASSWORD:"Сменить пароль",
      },
      TRANSFER:"Перенести все на ",
      SEND_REGISTRATION:"Отправить пароль",
      EMAIL_MODAL:{
        TITLE:"Сбросить пароль",
        TEXT:"Вы собираетесь сбросить пароль и отправить его по почте?"
      }
    },
    BOARD: {
      GENERAL: {
        TITLE: 'Доска напоминаний',
        YET_COLUMN_TITLE: 'Создано' ,
        IN_PROGRESS_COLUMN_TITLE: 'В процессе' ,
        COMPLETE_COLUMN_TITLE: 'Завершено' ,
        ARCHIVE_COLUMN_TITLE: 'Архив' ,
        NEW_CARD_TITLE: 'Новое напоминание',
        NO_SELECTED_TITLE: 'Не выбрано',
      },
      MODAL: {
        TITLE: 'Добавить / редактировать напоминие',
        MEMBERS_TITLE: 'Пользователи',
        EMPLOYEES_TITLE: 'Сотрудники',
        SEARCH_PLACEHOLDER: 'Поиск пользователей',
        ADD_MARKS: 'Добавить метки',
        MARKS_TITLE: 'Метки',
        NOTIFY_TITLE: 'Уведомления',
        NOTIFY_TITLE_ON: 'Уведомления Вкл.',
        NOTIFY_TITLE_OFF: 'Уведомления Выкл.',
        ADD_TO_CARD_TITLE: 'Добавить на карточку',
        DATE_TIME_TITLE: 'Дата / Время',
        SUBJECT_TITLE: 'Заголовок',
        DESCRIPTION_TITLE: 'Описание',
        FILES_TITLE: 'Вложения',
        COMMENT_TITLE: 'Действия / Комментарии',
        COMMENT_PLACEHOLDER: 'Введите сообщение...',
        DATE_TITLE: 'Дата',
        ARCHIVE: 'Архив',
        DELETE: 'Удалить',
      },
      DELETE_MODAL: {
        CARD_DELETE_TITLE: 'Удалить напоминание',
        CARD_DELETE_ATTENTION: 'Внимание!!',
        CARD_DELETE_TEXT: 'Вы уверены, что хотите удалить напоминание?',
      },
    },
    FOOTER: {
      TITLE: '',
      ABOUT: 'О нас',
      CONTACT: 'Контакты',
      CHANGE: 'Журнал изменений'
    },
    CHANGE_LOG: {
      TITLE: 'Журнал обновлений, правок и доработок',
      NEW_CHANGELOG:"Новый список изменений",
      NEW_CHANGELOG_TITLE:"Добавить новый список изменений",
      EDIT_CHANGELOG_TITLE:"Редактировать список изменений",
      DELETE_CHANGELOG_TITLE:"Удалить список изменений",
      VERSION:"Версия",
      DESCRIPTION:"Описание"
    },
    PRODUCTS: {
      MENU_TITLE: 'Товары',
      SEARCH_DESC: 'Поиск товара'
    }
  }
};
