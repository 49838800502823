import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tasks-drawer',
  templateUrl: './tasks-drawer.component.html',
  styleUrls: ['./tasks-drawer.component.scss']
})
export class TasksDrawerComponent implements OnInit {
  jobs: any;

  constructor() { }

  ngOnInit(): void {}

}
