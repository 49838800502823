<!--begin::Wrapper-->
<div>
  <!--begin::Form-->
  <form data-kt-search-element="form" class="w-100 position-relative" autocomplete="off">
    <!--begin::Icon-->
    <span
      [inlineSVG]="'./assets/media/icons/duotune/general/gen021.svg'"
      class="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0"
    ></span>
    <!--end::Icon-->

    <!--begin::Input-->
    <input type="text" class="form-control form-control-flush ps-10" name="search" value="" placeholder="Search..." data-kt-search-element="input" [ngModel]="keyword" (ngModelChange)="search($event)"/>
    <!--end::Input-->

    <!--begin::Spinner-->
    <span class="position-absolute top-50 end-0 translate-middle-y lh-0 me-1" data-kt-search-element="spinner" [ngClass]="searching ? '' : 'd-none'">
        <span class="spinner-border h-15px w-15px align-middle text-gray-400"></span>
    </span>
    <!--end::Spinner-->

    <!--begin::Reset-->
    <span class="btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0" data-kt-search-element="clear" [ngClass]="searching || keyword.length === 0 ? 'd-none' : ''" (click)="clearSearch()">
        <span
          [inlineSVG]="'./assets/media/icons/duotune/arrows/arr061.svg'"
          class="svg-icon svg-icon-2 svg-icon-lg-1 me-0"
        ></span>
    </span>
    <!--end::Reset-->

    <!--begin::Toolbar-->
<!--    <div class="position-absolute top-50 end-0 translate-middle-y" data-kt-search-element="toolbar" [ngClass]="searching || keyword.length > 0 ? 'd-none' : ''">-->
<!--      &lt;!&ndash;begin::Preferences toggle&ndash;&gt;-->
<!--      <div data-kt-search-element="preferences-show" class="btn btn-icon w-20px btn-sm btn-active-color-primary me-1" data-bs-toggle="tooltip" title="Show search preferences">-->
<!--          <span-->
<!--            [inlineSVG]="'./assets/media/icons/duotune/coding/cod001.svg'"-->
<!--            class="svg-icon svg-icon-1"-->
<!--          ></span>-->
<!--      </div>-->
<!--      &lt;!&ndash;end::Preferences toggle&ndash;&gt;-->

<!--      &lt;!&ndash;begin::Advanced search toggle&ndash;&gt;-->
<!--      <div data-kt-search-element="advanced-options-form-show" class="btn btn-icon w-20px btn-sm btn-active-color-primary" data-bs-toggle="tooltip" title="Show more search options">-->
<!--          <span-->
<!--            [inlineSVG]="'./assets/media/icons/duotune/arrows/arr072.svg'"-->
<!--            class="svg-icon svg-icon-2"-->
<!--          ></span>-->
<!--      </div>-->
<!--      &lt;!&ndash;end::Advanced search toggle&ndash;&gt;-->
<!--    </div>-->
    <!--end::Toolbar-->
  </form>
  <!--end::Form-->

  <!--begin::Separator-->
  <div class="separator border-gray-200 mb-6" [ngClass]="searching || keyword.length === 0 ? 'd-none' : ''"></div>
  <!--end::Separator-->


  <!--begin::Recently viewed-->
  <div data-kt-search-element="results" [ngClass]="searching || keyword.length === 0 ? 'd-none' : ''">
    <!--begin::Items-->
    <div class="scroll-y mh-200px mh-lg-325px">
      <ng-container *ngIf="dealTotal > 0">
        <!--begin::Category title-->
        <h3 class="fs-5 text-muted m-0 pb-5" data-kt-search-element="category-title">
          Сделки
        </h3>
        <!--end::Category title-->

        <!--begin::Item-->
        <a routerLink="/deals/{{item.id}}" *ngFor="let item of resultModelsDeals" class="d-flex text-dark text-hover-primary align-items-center mb-5">
          <!--begin::Symbol-->
          <div class="symbol symbol-40px me-4">
            <img src="{{ item.client.profile_avatar }}" alt=""/>
          </div>
          <!--end::Symbol-->

          <!--begin::Title-->
          <div class="d-flex flex-column justify-content-start fw-bold">
            <span class="fs-6 fw-bold">{{ item.client.name }}</span>
            <span
            class="badge-custom {{item.deal_types_id === 1 ? (settingsData|getClientOption:'deal_statuses':item.deal_statuses_id:true) : (settingsData|getClientOption:'deal_warehouse_statuses':item.deal_statuses_id:true)}}">
            {{item.deal_types_id === 1 ? (settingsData|getClientOption:'deal_statuses':item.deal_statuses_id) : (settingsData|getClientOption:'deal_warehouse_statuses':item.deal_statuses_id)}}</span>
            <div>
              <span *ngIf="item.name" class="fs-7 fw-bold text-muted">{{ item.name | slice: 0:48}} <span *ngIf="item.name.length >= 48">...</span></span>
              <span *ngIf="!item.name" class="fs-7 fw-bold text-muted">#{{ item.id }}</span>
            </div>
          </div>
          <!--end::Title-->
        </a>
        <!--end::Item-->

        <a routerLink="/deals" class="text-dark text-hover-primary mb-5" *ngIf="dealTotal > 5">Показать все ({{dealTotal}})</a>
      </ng-container>
      <ng-container *ngIf="userTotal > 0">
        <h3 class="fs-5 text-muted m-0 pt-5 pb-5" data-kt-search-element="category-title">Клиенты</h3>

        <!--begin::Item-->
        <a routerLink="/clients" [queryParams]="{client_id: item.user_id}" *ngFor="let item of resultModelsUsers" class="d-flex text-dark text-hover-primary align-items-center mb-5">
          <!--begin::Symbol-->
          <div class="symbol symbol-40px me-4">
            <img src="{{ item.profile_avatar }}" alt=""/>
          </div>
          <!--end::Symbol-->

          <div class="d-flex flex-column justify-content-start fw-bold">
            <span class="fs-6 fw-bold">{{ item.name }}</span>
          </div>
        </a>
        <!--end::Item-->

        <a routerLink="/clients" class="text-dark text-hover-primary mb-5" *ngIf="userTotal > 5">Показать все ({{userTotal}})</a>
      </ng-container>
    </div>
    <!--end::Items-->
  </div>
  <!--end::Recently viewed-->
</div>
<!--end::Wrapper-->
