<ng-container *ngIf="user.roles.length > 0 && !user.roles.includes(6) &&
 !user.roles.includes(7) &&
  !user.roles.includes(14) &&
   !user.roles.includes(15)">
  Dashboard
  <div class="menu-item">
    <a
      class="menu-link without-sub"
      routerLink="/dashboard"
      routerLinkActive="active"
    ><span class="menu-icon">
      <span
        [inlineSVG]="'./assets/media/icons/duotune/art/art002.svg'"
        class="svg-icon svg-icon-2"
      ></span>
    </span>
      <span class="menu-title" translate="MENU.DASHBOARD"></span
      ></a>
  </div>
</ng-container>

<!-- For China agents -->
<ng-container *ngIf="user.roles.length > 0 && user.roles.includes(12)">
<div class="menu-item">
  <div class="menu-content pt-8 pb-2">
      <span class="menu-section text-muted text-uppercase fs-8 ls-1" translate="MENU.PAGES"
      ></span>
  </div>
</div>
<!-- Jobs -->
  <div class="menu-item">
    <a  class="menu-link without-sub"
        routerLink="/jobs"
        routerLinkActive="active"
    ><span class="menu-icon">
      <i class="bi bi-calendar-week-fill fs-4"></i>
      </span>
      <span class="menu-title" translate="MENU.TASKS"></span>
    </a>
  </div>
</ng-container>
<!-- End: For China agents -->

<ng-container *ngIf="user.roles.length > 0 &&
( user.roles.includes(1)
|| user.roles.includes(2)
|| user.roles.includes(3)
|| user.roles.includes(4)
|| user.roles.includes(8)
|| user.roles.includes(8)
|| user.roles.includes(9))
">
  <div class="menu-item">
    <div class="menu-content pt-8 pb-2">
      <span class="menu-section text-muted text-uppercase fs-8 ls-1" translate="MENU.PAGES"
      ></span>
    </div>
  </div>

  <!-- Clients -->
  <div *ngIf="!user.roles.includes(14)" class="menu-item">
    <a  class="menu-link without-sub"
        routerLink="/clients"
        routerLinkActive="active"
    ><span class="menu-icon">
        <span
          [inlineSVG]="'./assets/media/icons/duotune/communication/com014.svg'"
          class="svg-icon svg-icon-2"
        ></span>
      </span>
      <span class="menu-title" translate="MENU.CLIENTS"></span>
    </a>
  </div>
  <!-- Deals -->
  <div class="menu-item">
    <a  class="menu-link without-sub"
        routerLink="/deals"
        routerLinkActive="active"
    ><span class="menu-icon">
        <span
          [inlineSVG]="'./assets/media/icons/duotune/general/gen002.svg'"
          class="svg-icon svg-icon-2"
        ></span>
      </span>
      <span class="menu-title" translate="MENU.DEALS"></span>
    </a>
  </div>

  <!-- Jobs -->
  <div class="menu-item">
    <a  class="menu-link without-sub"
        routerLink="/jobs"
        routerLinkActive="active"
    ><span class="menu-icon">
      <i class="bi bi-calendar-week-fill fs-4"></i>
      </span>
      <span class="menu-title" translate="MENU.TASKS"></span>
    </a>
  </div>

  <!-- Board -->
  <div class="menu-item" *ngIf="user.roles.length > 0">
    <a class="menu-link without-sub"
        routerLink="/board"
        routerLinkActive="active"
    ><span class="menu-icon">
      <i class="bi bi-kanban fs-4"></i>
      </span>
      <span class="menu-title" translate="MENU.BOARD"></span>
    </a>
  </div>

  <!-- Products -->
  <div class="menu-item" *ngIf="user.roles.length > 0">
    <a class="menu-link without-sub"
       routerLink="/products"
       routerLinkActive="active"
    ><span class="menu-icon">
      <i class="bi bi-basket2-fill fs-4"></i>
      </span>
      <span class="menu-title" translate="PRODUCTS.MENU_TITLE"></span>
    </a>
  </div>

  <!-- Docs -->
<!--  <div *ngIf="!user.roles.includes(14)" class="menu-item">-->
<!--    <div class="menu-content pt-8 pb-2">-->
<!--      <span class="menu-section text-muted text-uppercase fs-8 ls-1" translate="MENU.DOCS"-->
<!--      ></span>-->
<!--    </div>-->
<!--  </div>-->
  <!-- Invoices -->
<!--  <div *ngIf="!user.roles.includes(14)" class="menu-item">-->
<!--    <a  class="menu-link without-sub"-->
<!--        routerLink="/invoices"-->
<!--        routerLinkActive="active"-->
<!--    ><span class="menu-icon">-->
<!--        <span-->
<!--          [inlineSVG]="'./assets/media/icons/duotune/general/gen005.svg'"-->
<!--          class="svg-icon svg-icon-2"-->
<!--        ></span>-->
<!--      </span>-->
<!--      <span class="menu-title" translate="MENU.INVOICES"></span>-->
<!--    </a>-->
<!--  </div>-->

  <ng-container *ngIf="user.roles.length > 0 && !user.roles.includes(14)">
    <!-- Users -->
    <div class="menu-item">
      <div class="menu-content pt-8 pb-2">
        <span class="menu-section text-muted text-uppercase fs-8 ls-1" translate="MENU.USERS"></span>
      </div>
    </div>
    <div class="menu-item">
      <a  class="menu-link without-sub"
          routerLink="/users"
          routerLinkActive="active"
      ><span class="menu-icon">
        <span
          [inlineSVG]="'./assets/media/icons/duotune/communication/com006.svg'"
          class="svg-icon svg-icon-2"
        ></span>
      </span>
        <span class="menu-title" translate="MENU.USERS"></span>
      </a>
    </div>

    <!-- Roles -->
<!--    <div *ngIf="user.roles.length > 0 && (user.roles.includes(1) || user.roles.includes(2))" class="menu-item">-->
<!--      <a  class="menu-link without-sub"-->
<!--          routerLink="/roles"-->
<!--          routerLinkActive="active"-->
<!--      ><span class="menu-icon">-->
<!--        <span-->
<!--          [inlineSVG]="'./assets/media/icons/duotune/general/gen051.svg'"-->
<!--          class="svg-icon svg-icon-2"-->
<!--        ></span>-->
<!--      </span>-->
<!--        <span class="menu-title" translate="MENU.ROLES"></span>-->
<!--      </a>-->
<!--    </div>-->
    <!-- Statistics -->

    <div *ngIf="user.roles.length > 0 && (user.roles.includes(1) ||
     user.roles.includes(2) || user.roles.includes(3)) || user.roles.includes(4) || user.roles.includes(8)" class="menu-item">
      <a  class="menu-link without-sub"
          routerLink="/statistics"
          routerLinkActive="active"
      ><span class="menu-icon">
        <span
          [inlineSVG]="'./assets/media/icons/duotune/general/gen032.svg'"
          class="svg-icon svg-icon-2"
        ></span>
      </span>
        <span class="menu-title" translate="MENU.STATISTICS"></span>
      </a>
    </div>
  </ng-container>
</ng-container>

<!-- For Clients -->
<ng-container *ngIf="user.roles.length > 0 && user.roles.includes(6)">
  <div class="menu-item">
    <div class="menu-content pt-8 pb-2">
      <span class="menu-section text-muted text-uppercase fs-8 ls-1" translate="MENU.PAGES"
      ></span>
    </div>
  </div>
  <!-- Client Deals -->
  <div class="menu-item">
    <a  class="menu-link without-sub"
        routerLink="/client-deals"
        routerLinkActive="active"
    ><span class="menu-icon">
        <span
          [inlineSVG]="'./assets/media/icons/duotune/general/gen002.svg'"
          class="svg-icon svg-icon-2"
        ></span>
      </span>
      <span class="menu-title" translate="MENU.CLIENT_DEALS"></span>
    </a>
  </div>
</ng-container>

<!-- Shipments -->
<ng-container *ngIf="user.roles.length > 0 &&
 (user.roles.includes(10) ||
 user.roles.includes(1) ||
 user.roles.includes(4) ||
 user.roles.includes(15))">
  <div class="menu-item">
    <div class="menu-content pt-8 pb-2">
      <span class="menu-section text-muted text-uppercase fs-8 ls-1" translate="MENU.SHIPMENTS"
      ></span>
    </div>
  </div>
  <!-- Shipments -->
  <div data-kt-menu-trigger="click" class="menu-item menu-accordion">
    <!--begin:Menu link-->
    <span class="menu-link">
      <span class="menu-icon">
        <!--begin::Svg Icon | path: icons/duotune/communication/com005.svg-->
        <i class="fa-solid fa-anchor fs-4"></i>
        <!--end::Svg Icon-->
      </span>
      <span class="menu-title" translate="MENU.SHIPMENTS"></span>
      <span class="menu-arrow"></span>
    </span>
    <!--end:Menu link-->
    <!--begin:Menu sub-->
    <div class="menu-sub menu-sub-accordion" style="">
      <!--begin:Menu item-->
      <div class="menu-item">
        <!--begin:Menu link-->
        <a class="menu-link" routerLink="/shipments/sea" routerLinkActive="active">
          <span class="menu-bullet">
            <span class="bullet bullet-dot"></span>
          </span>
          <span class="menu-title" translate="MENU.SEA_LIST"></span>
        </a>
        <!--end:Menu link-->
      </div>
    </div>
    <!--end:Menu sub-->
  </div>
</ng-container>

<!-- Containers Ukraine -->
<ng-container  *ngIf="user.roles.length > 0 &&
 (user.roles.includes(1) ||
 user.roles.includes(11))">
  <div data-kt-menu-trigger="click" class="menu-item menu-accordion">
    <!--begin:Menu link-->
    <span class="menu-link">
      <span class="menu-icon">
        <!--begin::Svg Icon | path: icons/duotune/communication/com005.svg-->
        <div class="symbol symbol-15px symbol-circle me-2 flex-wrap">
          <img src="./assets/media/flags/ukraine.svg">
        </div>
        <!--end::Svg Icon-->
      </span>
      <span class="menu-title">Ukraine Ship's</span>
      <span class="menu-arrow"></span>
    </span>
    <!--end:Menu link-->
    <!--begin:Menu sub-->
    <div class="menu-sub menu-sub-accordion" style="">
      <!--begin:Menu item-->
      <div class="menu-item">
        <!--begin:Menu link-->
        <a class="menu-link" routerLink="/containers" routerLinkActive="active">
          <span class="menu-bullet">
            <span class="bullet bullet-dot"></span>
          </span>
          <span class="menu-title" translate="MENU.SEA_LIST"></span>
        </a>
        <!--end:Menu link-->
      </div>
    </div>
    <!--end:Menu sub-->
  </div>
</ng-container>
<!-- Tools -->
<ng-container *ngIf="user.roles.length > 0 && user.roles.includes(1)">
  <!-- Tools -->
  <div class="menu-item">
    <div class="menu-content pt-8 pb-2">
      <span class="menu-section text-muted text-uppercase fs-8 ls-1" translate="MENU.TOOLS.TITLE"
      ></span>
    </div>
  </div>

  <div data-kt-menu-trigger="click" class="menu-item menu-accordion">
    <!--begin:Menu link-->
    <span class="menu-link">
      <span class="menu-icon">
        <!--begin::Svg Icon | path: icons/duotune/communication/com005.svg-->
        <i class="fa-solid fa-screwdriver-wrench"></i>
        <!--end::Svg Icon-->
      </span>
      <span class="menu-title" translate="MENU.TOOLS.TITLE"></span>
      <span class="menu-arrow"></span>
    </span>
    <!--end:Menu link-->
    <!--begin:Menu sub-->
    <div class="menu-sub menu-sub-accordion" style="">
      <!--begin:Menu item-->
<!--      <div class="menu-item">-->
<!--        &lt;!&ndash;begin:Menu link&ndash;&gt;-->
<!--        <a class="menu-link" routerLink="/inspection-calc" routerLinkActive="active">-->
<!--          <span class="menu-bullet">-->
<!--            <span class="bullet bullet-dot"></span>-->
<!--          </span>-->
<!--          <span class="menu-title" translate="MENU.TOOLS.INSPECTION_TOOL"></span>-->
<!--        </a>-->
<!--        &lt;!&ndash;end:Menu link&ndash;&gt;-->
<!--      </div>-->

      <div class="menu-item">
        <!--begin:Menu link-->
        <a class="menu-link" routerLink="/history-log" routerLinkActive="active">
          <span class="menu-bullet">
            <span class="bullet bullet-dot"></span>
          </span>
          <span class="menu-title">История действий</span>
        </a>
        <!--end:Menu link-->
      </div>
      <!--end:Menu item-->
      <!--begin:Menu item-->
      <!--      <div class="menu-item">-->
      <!--        &lt;!&ndash;begin:Menu link&ndash;&gt;-->
      <!--        <a class="menu-link" routerLink="/shipments/avia" routerLinkActive="active">-->
      <!--          <span class="menu-bullet">-->
      <!--            <span class="bullet bullet-dot"></span>-->
      <!--          </span>-->
      <!--          <span class="menu-title" translate="MENU.AVIA_LIST"></span>-->
      <!--        </a>-->
      <!--        &lt;!&ndash;end:Menu link&ndash;&gt;-->
      <!--      </div>-->
      <!--end:Menu item-->
    </div>
    <!--end:Menu sub-->
  </div>
</ng-container>

<!-- Settings -->
<ng-container *ngIf="user.roles.length > 0 && (user.roles.includes(1) || user.roles.includes(2))">
  <!-- Settings -->
  <div class="menu-item">
    <div class="menu-content pt-8 pb-2">
      <span class="menu-section text-muted text-uppercase fs-8 ls-1" translate="MENU.SETTINGS"
      ></span>
    </div>
  </div>

  <div class="menu-item">
    <a  class="menu-link without-sub"
        routerLink="/settings"
        routerLinkActive="active"
    ><span class="menu-icon">
        <span
          [inlineSVG]="'./assets/media/icons/duotune/abstract/abs029.svg'"
          class="svg-icon svg-icon-2"
        ></span>
      </span>
      <span class="menu-title" translate="MENU.SETTINGS_SYS"></span>
    </a>
  </div>
</ng-container>
