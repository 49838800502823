<div
  id="kt_drawer_tasks"
  class="bg-white"
  data-kt-drawer="true"
  data-kt-drawer-name="tasks"
  data-kt-drawer-activate="true"
  data-kt-drawer-overlay="true"
  data-kt-drawer-width="{default:'300px', 'md': '500px'}"
  data-kt-drawer-direction="end"
  data-kt-drawer-toggle="#kt_drawer_tasks_toggle"
  data-kt-drawer-close="#kt_drawer_tasks_close"
>
  <div class="card w-100 rounded-0" id="kt_drawer_chat_messenger">
    <div class="card-header pe-5" id="kt_drawer_chat_messenger_header">
      <div class="card-title">
        <div class="d-flex justify-content-center flex-column me-3">
          <a class="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1 cursor-pointer">Напоминания о задачах</a>
        </div>
      </div>
      <div class="card-toolbar">
        <div
          class="btn btn-sm btn-icon btn-active-light-primary"
          id="kt_drawer_tasks_close"
        >
          <span class="svg-icon svg-icon-2"></span>
          <span
            [inlineSVG]="'./assets/media/icons/duotune/arrows/arr061.svg'"
            class="svg-icon svg-icon-2"
          ></span>
        </div>
      </div>
    </div>

  </div>
</div>
