import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getManagerName'
})
export class GetManagerNamePipe implements PipeTransform {

  transform(managers: [], manager_id:number): any {
    if(manager_id !== undefined) {
      let filterUsers:any = managers.filter((manager: any) => manager.user_id == manager_id);
      if (filterUsers.length > 0) {
        return filterUsers[0].name;
      } else {
        return null;
      }
    }
  }

}
