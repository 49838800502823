import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import * as moment from "moment";
import {SettingsService} from "../../../../layout/core/settings.service";
import {UsersService} from "../../../../../services/users.service";
import {DealJobsService} from "../../../../../services/deal-jobs.service";
import {DatePipe} from "@angular/common";
import {MessageService} from "primeng/api";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../../../modules/auth";
import {StateTopBarService} from "../../../../../services/state-top-bar.service";
import {NgbDropdown, NgbDropdownConfig} from "@ng-bootstrap/ng-bootstrap";
import {ISettings} from "../../../../layout/core/default-settings.config";

@Component({
  selector: 'app-main-modal',
  templateUrl: './main-modal.component.html',
  providers: [NgbDropdownConfig, DatePipe, NgbDropdown]
})
export class MainModalComponent implements OnInit {
  private unsubscribe: Subscription[] = [];
  jobForm = new FormGroup({
    deal_id: new FormControl(),
    user_id: new FormControl(-1, Validators.required),
    date: new FormControl(new Date, Validators.required),
    time: new FormControl(),
    message: new FormControl(),
    job_type_id: new FormControl(1, Validators.required),
    result_message: new FormControl(),
    end_time: new FormControl(),
    status: new FormControl(),
    created_at: new FormControl(),
    time_templ: new FormControl(4),
    client_id: new FormControl(4)
  });
  managers: any;
  clients: any;
  errors: any = false;
  loading: string = '';
  buttonsBlock: boolean = false;
  loadingData: boolean = false;
  settingsData: ISettings|any = {};

  @ViewChild('jobModalClose') jobModalClose: ElementRef;

  constructor(private settings: SettingsService,
              private usersService: UsersService,
              private dealJobService: DealJobsService,
              private datePipe: DatePipe,
              private messageService: MessageService,
              private auth: AuthService,
              private stateService: StateTopBarService) {}

  ngOnInit(): void {
    this.settingsData = this.settings.getSettings();
    this.jobForm.patchValue({
      user_id: this.auth.currentUserValue?.id,
      deal_id: null,
      message: '',
      date: new Date(),
      time: '23:59:00',
      job_type_id: 1,
      status: 1
    });
  }

  getRoleName(id: number | undefined){
    if(id){
      let roles_name:any = this.settings.getProp('roles');
      return roles_name?.filter((x:any) => x.id == id)[0].name;
    }
  }

  getClientOption(optionName:string, optionId?:number, className?: boolean){
    const options:any = this.settings.getProp(optionName);
    if(optionId && !className){
      return options.find((value:any) => value.id == optionId).name;
    } else if(optionId && className){
      return options.find((value:any) => value.id == optionId).class;
    } else {
      return options;
    }
  }

  addJob(){
    if(!this.jobForm.valid){
      this.getFormValidationErrors();
      this.errors = true;
      return;
    }

    this.loading = 'on';
    this.buttonsBlock = true;

    const date = this.datePipe.transform(this.jobForm.value.date, 'dd.MM.yyyy');
    const time = this.jobForm.value.time;
    let status = 1;

    const endTime = moment(date + ' ' + time, 'DD.MM.YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');

    let endTimeCheck = moment(date + ' ' + time, 'DD.MM.YYYY HH:mm:ss');
    let todayCheck = moment();

    if(endTimeCheck.diff(todayCheck) < 0){
      status = 3;
    }

    this.jobForm.patchValue({
      end_time: endTime,
      user_id: this.jobForm.value.user_id ? this.jobForm.value.user_id : this.auth.currentUserValue?.id,
      job_type_id: this.jobForm.value.job_type_id ? this.jobForm.value.job_type_id : 1,
      status: status
    });

    this.dealJobService.newDealJob(this.jobForm.value)
      .subscribe(jobs => {
        this.jobForm.reset();
        this.jobModalClose.nativeElement.click();

        this.messageService.add({severity:'success', summary:'Успешно', detail:'Задача создана'});
        this.stateService.setRefresh(true);

        this.errors = false;
      }, error => {
        this.messageService.add({severity:'error', summary:'Ошибка', detail:'Ошибка сервера'});
      }, () => {
        this.loading = '';
        this.buttonsBlock = false;
      })
  }

  loadUsersData(){
    if(!this.clients || !this.managers){
      this.loadingData = true;
      this.loadClients().subscribe(clients => {
        this.clients = clients;
        this.loadingData = false;
      });
      this.loadManagers().subscribe(managers => {
        this.managers = managers;
      });
    }
  }

  loadManagers(){
    return this.usersService.getManagers();
  }

  loadClients(){
    return this.usersService.getAllClientsShortData();
  }

  get user_id():any{
    return this.jobForm.get('user_id');
  }

  get job_type_id():any{
    return this.jobForm.get('job_type_id');
  }

  get date():any{
    return this.jobForm.get('date');
  }

  getFormValidationErrors() {
    Object.keys(this.jobForm.controls).forEach(key => {
      const controlErrors: any = this.jobForm.get(key)?.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
