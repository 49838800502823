import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthService} from "../modules/auth";
import {Observable, of} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient, private auth: AuthService) {
    this.initService();
  }

  initService() {
    const authData = this.auth.getAuthLocal();
    if (!authData || !authData.token) {
      return of(undefined);
    }

    this.httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${authData.token}`,
    });
  }

  getNotificationsByUserId(): Observable<any>{
    return this.http.get(`${environment.apiUrl}/notifications/${this.auth.currentUserValue?.id}`, {
      headers: this.httpHeaders,
    });
  }
  markAsSeen(data:any): Observable<any>{
    return this.http.post(`${environment.apiUrl}/notifications/${this.auth.currentUserValue?.id}/seen`,data, {
      headers: this.httpHeaders,
    });
  }
  markNotificationsAsRead(){

  }

}
