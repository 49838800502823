import { Injectable } from '@angular/core';
import {SettingsService} from "../_metronic/layout/core/settings.service";

@Injectable({
  providedIn: 'root'
})
export class OptionsService {
  constructor(private settings: SettingsService) { }

  getClientOption(optionName:string, optionId?:number, className?: boolean){
    const options:any = this.settings.getProp(optionName);
    if(optionId && !className){
      return options.find((value:any) => value.id == optionId).name;
    } else if(optionId && className){
      return options.find((value:any) => value.id == optionId).class;
    } else {
      return options;
    }
  }
}
