<div
  class="d-flex flex-column bgi-no-repeat rounded-top"
  [style.background-image]="'url(./assets/media/misc/pattern-1.jpg)'"
>
  <h3 class="text-white fw-bold m-10">
    {{"GENERAL.NOTIFICATIONS"|translate}}
<!--    <span class="fs-8 opacity-75 ps-3">24 reports</span>-->
  </h3>

<!--  <ul class="nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9">-->
<!--    <li class="nav-item">-->
<!--      <a-->
<!--        class="-->
<!--          nav-link-->
<!--          text-white-->
<!--          opacity-75 opacity-state-100-->
<!--          pb-4-->
<!--          cursor-pointer-->
<!--        "-->
<!--        data-bs-toggle="tab"-->
<!--        [ngClass]="activeTabId === 'kt_topbar_notifications_1' ? 'active' : ''"-->
<!--        (click)="setActiveTabId('kt_topbar_notifications_1')"-->
<!--      >-->
<!--        Alerts-->
<!--      </a>-->
<!--    </li>-->

<!--&lt;!&ndash;    <li class="nav-item">&ndash;&gt;-->
<!--&lt;!&ndash;      <a&ndash;&gt;-->
<!--&lt;!&ndash;        class="&ndash;&gt;-->
<!--&lt;!&ndash;          nav-link&ndash;&gt;-->
<!--&lt;!&ndash;          text-white&ndash;&gt;-->
<!--&lt;!&ndash;          opacity-75 opacity-state-100&ndash;&gt;-->
<!--&lt;!&ndash;          pb-4&ndash;&gt;-->
<!--&lt;!&ndash;          cursor-pointer&ndash;&gt;-->
<!--&lt;!&ndash;        "&ndash;&gt;-->
<!--&lt;!&ndash;        data-bs-toggle="tab"&ndash;&gt;-->
<!--&lt;!&ndash;        [ngClass]="activeTabId === 'kt_topbar_notifications_2' ? 'active' : ''"&ndash;&gt;-->
<!--&lt;!&ndash;        (click)="setActiveTabId('kt_topbar_notifications_2')"&ndash;&gt;-->
<!--&lt;!&ndash;      >&ndash;&gt;-->
<!--&lt;!&ndash;        Updates&ndash;&gt;-->
<!--&lt;!&ndash;      </a>&ndash;&gt;-->
<!--&lt;!&ndash;    </li>&ndash;&gt;-->

<!--&lt;!&ndash;    <li class="nav-item">&ndash;&gt;-->
<!--&lt;!&ndash;      <a&ndash;&gt;-->
<!--&lt;!&ndash;        class="&ndash;&gt;-->
<!--&lt;!&ndash;          nav-link&ndash;&gt;-->
<!--&lt;!&ndash;          text-white&ndash;&gt;-->
<!--&lt;!&ndash;          opacity-75 opacity-state-100&ndash;&gt;-->
<!--&lt;!&ndash;          pb-4&ndash;&gt;-->
<!--&lt;!&ndash;          cursor-pointer&ndash;&gt;-->
<!--&lt;!&ndash;        "&ndash;&gt;-->
<!--&lt;!&ndash;        data-bs-toggle="tab"&ndash;&gt;-->
<!--&lt;!&ndash;        [ngClass]="activeTabId === 'kt_topbar_notifications_3' ? 'active' : ''"&ndash;&gt;-->
<!--&lt;!&ndash;        (click)="setActiveTabId('kt_topbar_notifications_3')"&ndash;&gt;-->
<!--&lt;!&ndash;      >&ndash;&gt;-->
<!--&lt;!&ndash;        Logs&ndash;&gt;-->
<!--&lt;!&ndash;      </a>&ndash;&gt;-->
<!--&lt;!&ndash;    </li>&ndash;&gt;-->
<!--  </ul>-->
</div>

<div class="tab-content">
  <div

  >
    <div class="scroll-y mh-400px my-5 px-2">
      <div *ngFor="let alert of notifications; let i = index" class="d-flex flex-stack p-2 m-1" [ngClass]="{' rounded': !alert.is_seen}"
           [ngStyle]="{'background-color': !alert.is_seen ? '#fce6ed' : ''}"
           appInView (inView)="itemInView(alert.id)"
      >
        <div class="d-flex align-items-center">
          <div class="symbol symbol-35px me-4">
            <span class="symbol-label" >
              <span
                [inlineSVG]="'./assets/media/' + alert.icon"
                class="svg-icon svg-icon-2"
                [ngClass]="'svg-icon-' + alert.state"
              ></span>
            </span>
          </div>

          <div class="mb-0 me-2">
            <a  class="fs-6 text-gray-800 text-hover-primary fw-bolder">
              {{ alert.title }}
            </a>
            <div class="text-gray-400 text-hover-primary fs-7 " (click)="linkDeal(alert?.job?.deal_id)"><a>Deal#{{ alert?.job?.deal_id }}</a> </div>
          </div>
        </div>

        <span class="badge badge-light fs-8">{{alert?.job?.end_time|timeDifference}}</span>
      </div>
      <div class="text-gray-400 fs-7" *ngIf="notifications.length === 0"> {{"GENERAL.NO_NOTIFICATIONS"|translate}}</div>
    </div>

  </div>

</div>
