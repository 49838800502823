import {ChangeDetectorRef, Component, HostBinding, OnInit} from '@angular/core';
import {SearchService} from "../../../../../../services/search.service";
import {debounceTime, distinctUntilChanged, Subject, Subscription} from "rxjs";
import {switchMap} from "rxjs/operators";
import {Client} from "../../../../../../pages/clients/clients.component";
import {OptionsService} from "../../../../../../services/options.service";
import {Deal} from "../../../../../../models/deal";
import {ISettings} from "../../../../../layout/core/default-settings.config";
import {SettingsService} from "../../../../../layout/core/settings.service";

@Component({
  selector: 'app-search-result-inner',
  templateUrl: './search-result-inner.component.html',
})
export class SearchResultInnerComponent implements OnInit {
  @HostBinding('class') class = 'menu menu-sub menu-sub-dropdown p-7 w-325px w-md-375px';
  @HostBinding('attr.data-kt-menu') dataKtMenu = 'true';
  @HostBinding('attr.data-kt-search-element') dataKtSearch = 'content';

  resultModelsDeals: Deal[];
  resultModelsUsers: Client[];
  dealTotal: number;
  userTotal: number;
  recentlySearchedModels: Array<ResultModel> = recentlySearchedModels;

  keyword: string = '';
  searching: boolean = false;

  private readonly searchSubject = new Subject<string | undefined>();
  private searchSubscription?: Subscription;
  searchResult: any = [];
  searchQuery: string;
  settingsData: ISettings|any = {};


  constructor(private settings: SettingsService,
              private searchService: SearchService,
              public optionsService: OptionsService) {
  }

  ngOnInit(): void {
    this.searchSubscription = this.searchSubject
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((searchQuery) => {
          this.searching = true;
          return this.searchEngine(this.searchQuery)
        })
      )
      .subscribe((results) => {
        this.resultModelsDeals = results.deals;
        this.resultModelsUsers = results.clients;
        this.dealTotal = results.total.deals;
        this.userTotal = results.total.clients;

        this.searching = false;
      });
    this.settingsData = this.settings.getSettings();
  }

  search(keyword: string) {
    this.searchQuery = keyword;
    this.searchSubject.next(this.searchQuery?.trim());

    this.keyword = keyword;
  }

  searchEngine(keyword: string){
    return this.searchService.search(keyword);
  }

  clearSearch() {
    this.keyword = '';
  }

  public ngOnDestroy(): void {
    this.searchSubscription?.unsubscribe();
  }
}

interface ResultModel {
  icon?: string;
  image?: string;
  title: string;
  description: string;
}

// const resultModels: Array<ResultModel> = [
//   {
//     'image': './assets/media/avatars/300-6.jpg',
//     'title': 'Karina Clark',
//     'description': 'Marketing Manager'
//   },
//   {
//     'image': './assets/media/avatars/300-2.jpg',
//     'title': 'Olivia Bold',
//     'description': 'Software Engineer'
//   },
//   {
//     'image': './assets/media/avatars/300-9.jpg',
//     'title': 'Ana Clark',
//     'description': 'UI/UX Designer'
//   },
//   {
//     'image': './assets/media/avatars/300-14.jpg',
//     'title': 'Nick Pitola',
//     'description': 'Art Director'
//   },
//   {
//     'image': './assets/media/avatars/300-11.jpg',
//     'title': 'Edward Kulnic',
//     'description': 'System Administrator'
//   }
// ];

const recentlySearchedModels: Array<ResultModel> = [
  {
    'icon': './assets/media/icons/duotune/electronics/elc004.svg',
    'title': 'BoomApp by Keenthemes',
    'description': '#45789'
  }, {
    'icon': './assets/media/icons/duotune/graphs/gra001.svg',
    'title': '"Kept API Project Meeting',
    'description': '#84050'
  }, {
    'icon': './assets/media/icons/duotune/graphs/gra006.svg',
    'title': '"KPI Monitoring App Launch',
    'description': '#84250'
  }, {
    'icon': './assets/media/icons/duotune/graphs/gra002.svg',
    'title': 'Project Reference FAQ',
    'description': '#67945'
  }, {
    'icon': './assets/media/icons/duotune/communication/com010.svg',
    'title': '"FitPro App Development',
    'description': '#84250'
  }, {
    'icon': './assets/media/icons/duotune/finance/fin001.svg',
    'title': 'Shopix Mobile App',
    'description': '#45690'
  }, {
    'icon': './assets/media/icons/duotune/graphs/gra002.svg',
    'title': '"Landing UI Design" Launch',
    'description': '#24005'
  }
];
