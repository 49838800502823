import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getUserPic'
})
export class GetUserPicPipe implements PipeTransform {

  transform(users: any, id:number): any {
    if (id){
      return users?.filter((x: any) => x.id === id)[0].profile_avatar
    }
  }

}
