import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { LayoutService } from '../../core/layout.service';
import {NgbDropdown, NgbDropdownConfig} from "@ng-bootstrap/ng-bootstrap";
import {Observable, Subscription, switchMap} from "rxjs";
import {AuthService, UserType} from "../../../../modules/auth";
import {StateTopBarService} from "../../../../services/state-top-bar.service";
import {SettingsService} from "../../core/settings.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DatePipe} from "@angular/common";
import {UsersService} from "../../../../services/users.service";
import {DealJobsService} from "../../../../services/deal-jobs.service";
import * as moment from "moment";
import {MessageService} from "primeng/api";
import {ISettings} from "../../core/default-settings.config";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  providers: [NgbDropdownConfig, DatePipe, NgbDropdown]
})
export class TopbarComponent implements OnInit,OnDestroy {
  toolbarButtonMarginClass = 'ms-1 ms-lg-3';
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px';
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px';
  toolbarButtonIconSizeClass = 'svg-icon-1';
  headerLeft: string = 'menu';
  user$: Observable<UserType>;
  hasDanger: boolean = false;
  allComplete: boolean = false;
  user: any;
  settingsData: any = {};


  private unsubscribe: Subscription[] = [];

  constructor(private layout: LayoutService,
              private auth: AuthService,
              private stateService: StateTopBarService,
              private settings: SettingsService) {}

  ngOnInit(): void {
    this.settingsData = this.settings.getSettings();
    this.user$ = this.auth.currentUserSubject.asObservable();
    this.headerLeft = this.layout.getProp('header.left') as string;

    this.user$.subscribe((user:any) => {
      user.roles = user.roles.sort((a:any, b:any) => a - b)
      this.user = user;
      console.log(this.user)
      if(user.jobs){
        let jobsCount = user.jobs.length;

        user.jobs.map((job:any) => {
          if(job.status == 3){
            this.hasDanger = true;
          }
          if(job.status == 2){
            jobsCount--;
          }
        })

        if(jobsCount <= 0){
          this.allComplete = true;
        }
      }
    })

    this.stateService.getRefresh().subscribe((value:boolean) => {
      if(value){
        this.auth.getUserByToken().subscribe(data => {
          if(data?.jobs){
            let jobsCount = data.jobs.length;
            this.hasDanger = false;
            this.allComplete = false;

            data.jobs.map((job:any) => {
              if(job.status == 3){
                this.hasDanger = true;
              }
              if(job.status == 2){
                jobsCount--;
              }
            })

            if(jobsCount <= 0){
              this.allComplete = true;
            }

          }
        });
      }
    });
  }


  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
