<div
  [ngClass]="footerContainerCssClasses"
  class="
    d-flex
    flex-column flex-md-row
    align-items-center
    justify-content-between
  "
>
  <!-- begin::Copyright  -->
  <div class="text-dark">
    <span class="text-muted fw-bold me-2">ARDI Express {{ currentDateStr }} &copy;</span>
    <a class="text-gray-800 text-hover-primary cursor-pointer"> CRM {{appVersion}}</a>
  </div>
  <!-- end::Copyright  -->
  <div class="d-flex timer-container justify-content-center flex-wrap footer-timers">
    <div class="fw-bolder d-flex align-items-center justify-content-start fixwidth mx-7">
      <div class="symbol symbol-20px symbol-circle me-2 mt-0 flex-wrap">
        <img class="" src="./assets/media/flags/ukraine.svg"/>
      </div>
        {{timeUkraine}}
    </div>
    <div class="fw-bolder d-flex align-items-center justify-content-start fixwidth mx-7">
      <div class="symbol symbol-20px symbol-circle me-2 mt-0 flex-wrap">
        <img class="" src="./assets/media/flags/austria.svg"/>
      </div>
        {{timeEurope}}
    </div>
    <div class="fw-bolder d-flex align-items-center justify-content-start fixwidth mx-7">
      <div class="symbol symbol-20px symbol-circle me-2 mt-0 flex-wrap">
        <img class="" src="./assets/media/flags/united-states.svg"/>
      </div>
        {{timeUSA}}
    </div>
    <div class="fw-bolder d-flex align-items-center justify-content-start fixwidth mx-7">
      <div class="symbol symbol-20px symbol-circle me-2 mt-0 flex-wrap">
        <img class="" src="./assets/media/flags/china.svg"/>
      </div>
        {{timeChina}}
    </div>
  </div>
  <!-- begin::Nav  -->
  <ul class="menu menu-gray-600 menu-hover-primary fw-bold order-1">
    <li class="menu-item">
      <a class="menu-link pe-0 pe-2 cursor-pointer" routerLink="/changelog"> {{'FOOTER.CHANGE' | translate}} </a>
    </li>
  </ul>
  <!-- end::Nav  -->
</div>
