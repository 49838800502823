import { Injectable } from '@angular/core';
import * as moment from "moment";
import {TranslationService} from "../modules/i18n";

@Injectable({
  providedIn: 'root'
})
export class TimeDifferenceService {

  constructor(private translation:TranslationService) { }

  getTimeDifferenceString(date: any): string{
    let locale = this.translation.getSelectedLanguage()
    let days:string = ''
    let hours:string = ''
    if(locale==='en'){
      days = ' d.'
      hours = ' h.'
    }
    if(locale==='ru'){
      days = ' дн.'
      hours = ' ч.'
    }
    if(locale==='ua'){
      days = ' дн.'
      hours = ' год.'
    }
    const dateToday = moment();
    const dayDiff = moment(date);
    let resultD = dayDiff.diff(dateToday, 'days');
    let resultH = dayDiff.diff(dateToday, 'hours');

    if(resultD < 0 || resultH < 0){
      if(resultD <= -1){
        return resultD * -1 + days;
      } else {
        return resultH * -1 + hours;
      }
    } else if(resultD == 0){
      return resultH + hours;
    }
    return resultD + days;
  }
}
