<!-- Search toolbar -->
<ng-container *ngIf="!user.roles.includes(6)">
  <div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
    <div
      class="btn btn-icon btn-active-light-primary position-relative"
      [ngClass]="toolbarButtonHeightClass"
      ngbDropdown
    >
      <span
        [inlineSVG]="'./assets/media/icons/duotune/general/gen021.svg'"
        class="svg-icon"
        [ngClass]="toolbarButtonIconSizeClass"
        ngbDropdownToggle
      ></span>
      <app-search-result-inner ngbDropdownMenu class="w-md-600px"></app-search-result-inner>
    </div>
  </div>
</ng-container>

<!-- Tasks toolbar-->
<!--<ng-container *ngIf="user$ | async as _user">-->
<!--  <div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">-->
<!--    <div-->
<!--      class="btn btn-icon btn-active-light-primary position-relative"-->
<!--      [ngClass]="toolbarButtonHeightClass"-->
<!--      data-bs-toggle="modal" data-bs-target="#kt_modal_add_task_new"-->
<!--    >-->
<!--      <span class="">-->
<!--        <i class="bi bi-calendar-plus fs-3"></i>-->
<!--      </span>-->
<!--    </div>-->

<!--    &lt;!&ndash; TODO: To child component  &ndash;&gt;-->
<!--  </div>-->
<!--</ng-container>-->
<!-- End: Tasks toolbar-->


<!-- Activities-->
<!--<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">-->
<!--  &lt;!&ndash; begin::Drawer toggle &ndash;&gt;-->
<!--  <div-->
<!--    class="btn btn-icon btn-active-light-primary"-->
<!--    [ngClass]="toolbarButtonHeightClass"-->
<!--    id="kt_activities_toggle"-->
<!--  >-->
<!--    <span-->
<!--      [inlineSVG]="'./assets/media/icons/duotune/general/gen032.svg'"-->
<!--      class="svg-icon"-->
<!--      [ngClass]="toolbarButtonIconSizeClass"-->
<!--    ></span>-->
<!--  </div>-->
<!--  &lt;!&ndash; end::Drawer toggle &ndash;&gt;-->
<!--</div>-->


<!--<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">-->
<!--  <div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">-->
<!--    <div-->
<!--      class="btn btn-icon btn-active-light-primary position-relative"-->
<!--      [ngClass]="toolbarButtonHeightClass"-->
<!--      ngbDropdown-->
<!--    >-->
<!--      <span-->
<!--      [inlineSVG]="'./assets/media/icons/duotune/general/gen007.svg'"-->
<!--        class="svg-icon"-->
<!--        [ngClass]="toolbarButtonIconSizeClass"-->
<!--        ngbDropdownToggle-->
<!--      ></span>-->
<!--      <span *ngIf="user?.not_seen_notifications" class="bullet bullet-dot bg-danger h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"></span>-->
<!--      <app-notifications-inner ngbDropdownMenu></app-notifications-inner>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<!-- CHAT-->
<!--<ng-container *ngIf="user$ | async as _user">-->
<!--  <div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">-->
<!--    &lt;!&ndash; begin::Menu wrapper &ndash;&gt;-->
<!--    <div-->
<!--      class="btn btn-icon btn-active-light-primary position-relative"-->
<!--      [ngClass]="toolbarButtonHeightClass"-->
<!--      id="kt_drawer_tasks_toggle">-->
<!--      <span [inlineSVG]="'./assets/media/icons/duotune/general/gen007.svg'" class="svg-icon" [ngClass]="toolbarButtonIconSizeClass"></span>-->
<!--      <span *ngIf="_user.jobs.length > 0 && !hasDanger && !allComplete" class="bullet bullet-dot bg-primary h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"></span>-->
<!--      <span *ngIf="_user.jobs.length > 0 && hasDanger && !allComplete" class="bullet bullet-dot bg-danger h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"></span>-->
<!--    </div>-->
<!--    &lt;!&ndash; end::Menu wrapper &ndash;&gt;-->
<!--  </div>-->
<!--</ng-container>-->

<!-- Quick links-->
<!--<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">-->
<!--  <div-->
<!--    class="btn btn-icon btn-active-light-primary"-->
<!--    [ngClass]="toolbarButtonHeightClass"-->
<!--    data-kt-menu-trigger="click"-->
<!--    data-kt-menu-attach="parent"-->
<!--    data-kt-menu-placement="bottom-end"-->
<!--    data-kt-menu-flip="bottom"-->
<!--  >-->
<!--    <span-->
<!--      [inlineSVG]="'./assets/media/icons/duotune/general/gen025.svg'"-->
<!--      class="svg-icon"-->
<!--      [ngClass]="toolbarButtonIconSizeClass"-->
<!--    ></span>-->
<!--  </div>-->
<!--  <app-quick-links-inner></app-quick-links-inner>-->
<!--</div>-->

<!-- begin::User -->
<ng-container *ngIf="user$ | async as _user">
  <div
    class="d-flex align-items-center"
    [ngClass]="toolbarButtonMarginClass"
    id="kt_header_user_menu_toggle"
  >
    <!-- begin::Toggle -->
    <div
      class="cursor-pointer symbol d-flex flex-end text-end"
      [ngClass]="toolbarUserAvatarHeightClass"
      ngbDropdown
    >
      <div class="d-flex me-2 flex-column">
        <span class="text-muted">{{_user.name}}</span>
        <span class="fw-bolder">{{settingsData|getClientOption:'roles':_user.roles[0]}}</span>
      </div>

      <img *ngIf="_user.profile_avatar" src="{{_user.profile_avatar}}" alt="Profile avatar" ngbDropdownToggle id="dropdownUserMenu" class="pics-fit"/>
      <div *ngIf="!_user.profile_avatar" class="main-profile-image" ngbDropdownToggle id="">{{_user.name | slice:0:1}}</div>
      <div ngbDropdownMenu aria-labelledby="dropdownUserMenu">
        <app-user-inner></app-user-inner>
      </div>
    </div>
    <!-- end::Toggle  -->
  </div>
</ng-container>
<!-- end::User -->

<ng-container *ngIf="headerLeft === 'menu'">
  <div
    class="d-flex align-items-center d-none ms-2 me-n3"
    title="Show header menu"
  >
    <div
      class="
        btn btn-icon btn-active-light-primary
        w-30px
        h-30px
        w-md-40px
        h-md-40px
      "
      id="kt_header_menu_mobile_toggle"
    >
      <span
        [inlineSVG]="'./assets/media/icons/duotune/text/txt001.svg'"
        class="svg-icon svg-icon-1"
      ></span>
    </div>
  </div>
</ng-container>


