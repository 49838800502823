import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchInArrayObjects'
})
export class SearchInArrayObjectsPipe implements PipeTransform {

  transform(array: any[], property:any,value:any) {
    return array.find((elem)=>{
      if (elem[property] === value){
        return elem
      }
    })
  }

}
