import { Component, HostBinding, OnInit } from '@angular/core';
import {NotificationsService} from "../../../../../../services/notifications.service";
import {Router,NavigationEnd} from "@angular/router";
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {AuthService} from "../../../../../../modules/auth";



@Component({
  selector: 'app-notifications-inner',
  templateUrl: './notifications-inner.component.html',
})
export class NotificationsInnerComponent implements OnInit {
  @HostBinding('class') class =
    'menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px';
  @HostBinding('attr.data-kt-menu') dataKtMenu = 'true';

  seenItems:any = [];

  activeTabId = 'kt_topbar_notifications_2';
  notifications: any = []
  user:any
  seenItemsSubject = new Subject<any>();

  constructor( private notificationsService:NotificationsService,
               private router: Router,
               private auth: AuthService,
               ) {
    this.seenItemsSubject.pipe(
      debounceTime(2000)
    ).subscribe(items => {
      items.forEach((elem:any)=>{
        let alert = this.notifications.find((item:any) => item.id === elem);
        alert.is_seen = 1
      })
      this.notificationsService.markAsSeen(items).subscribe((data)=>{
      });
      this.checkIfAllSeen()
    });

  }

  ngOnInit(): void {
    this.user = this.auth.currentUserValue;
    this.activeTabId = 'kt_topbar_notifications_1'
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.getNotifications();
      }
    });
    this.getNotifications()
  }
  getNotifications(){
    this.notificationsService.getNotificationsByUserId().subscribe((res)=>{
      this.prepareNotificationsForRender(res.data)
      this.notifications = res.data
      this.checkIfSomeSeen()
    })
  }
  checkIfAllSeen(){
    if(this.notifications.every((obj:any) => obj.is_seen === 1)){
      this.user.not_seen_notifications = false
    }
  }
  checkIfSomeSeen(){
    if(this.notifications.some((obj:any) => obj.is_seen === 0)){
      this.user.not_seen_notifications = true
    }
  }

  itemInView(id: any) {
    let alert = this.notifications.find((item:any) => item.id === id);
    if(!alert.is_seen){
      this.seenItems.push(id);
      this.seenItemsSubject.next(this.seenItems);
    }
  }

  linkDeal(id: any) {
    this.router.navigateByUrl(`/deals/${id}`);
  }

  prepareNotificationsForRender(array:any){
    array.forEach((elem:any)=>{
      if(elem.action === "expired_job"){
        elem.title = 'Expired tasks'
        elem.icon = 'icons/duotune/general/gen044.svg'
        elem.state = 'danger'
      }
    })
  }

}

