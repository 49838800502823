import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class StateTopBarService {
  private refresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  //Getting refresh state: boolean (true/false)
  public getRefresh(): Observable<boolean> {
    return this.refresh.asObservable();
  }

  //Setting refresh state: boolean (true/false)
  public setRefresh(value: boolean): void {
    this.refresh.next(value);
  }
}
