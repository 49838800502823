<div class="modal fade" id="kt_modal_add_task_new" tabindex="-1" aria-hidden="true">
  <!--begin::Modal dialog-->
  <div class="modal-dialog modal-dialog-centered mw-650px">
    <!--begin::Modal content-->
    <div class="modal-content">
      <!--begin::Form-->
      <form class="form fv-plugins-bootstrap5 fv-plugins-framework" action="#" id="kt_modal_add_task_new_form" [formGroup]="jobForm">
        <!--begin::Modal header-->
        <div class="modal-header" id="kt_modal_add_task_new_header">
          <!--begin::Modal title-->
          <h2 class="fw-bolder">Поставить / редактировать задачу</h2>
          <!--end::Modal title-->
          <!--begin::Close-->
          <div #jobModalClose id="kt_modal_add_task_new_close" class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal" aria-label="Закрыть">
            <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
            <span class="svg-icon svg-icon-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                  <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                </svg>
              </span>
            <!--end::Svg Icon-->
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body py-10 px-lg-17">
          <!--begin::Scroll-->
          <div class="scroll-y me-n7 pe-7" id="kt_modal_add_task_new_scroll" data-kt-scroll="true"
               data-kt-scroll-activate="{default: false, lg: true}"
               data-kt-scroll-max-height="auto"
               data-kt-scroll-dependencies="#kt_modal_add_deal_header"
               data-kt-scroll-wrappers="#kt_modal_add_deal_scroll"
               data-kt-scroll-offset="300px" style="max-height: 205px;">
            <!--begin::Input group-->
            <div class="fv-row mb-7 fv-plugins-icon-container">
              <!--begin::Label-->
              <label class="fs-6 fw-bold mb-2">Примечание</label>
              <!--end::Label-->
              <!--begin::Input-->
              <!--              <input type="text" class="form-control form-control-solid" placeholder="" formControlName="message">-->

              <textarea formControlName="message" rows="2" class="form-control form-control-solid mb-3" autosize></textarea>
              <!--end::Input-->
              <div class="fv-plugins-message-container invalid-feedback"></div>
            </div>

            <div class="fv-row mb-7 fv-plugins-icon-container">
              <!--begin::Label-->
              <label class="fs-6 fw-bold mb-2 required">
                <span>Клиент</span>
              </label>
              <!--end::Label-->
              <ng-select [items]="clients"
                         bindLabel="name"
                         bindValue="user_id"
                         formControlName="client_id"
                         [virtualScroll]="true"
                         [loading]=loadingData
                         loadingText='Loading...'
                         (click)="loadUsersData()">
              </ng-select>
              <!--              <div *ngIf="errors && user_id?.hasError('required')" class="fv-plugins-message-container invalid-feedback">Поле не заполнено</div>-->
              <!--end::Input-->
              <div class="fv-plugins-message-container invalid-feedback"></div>
            </div>

            <div class="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
              <div class="col">
                <div class="d-flex flex-column mb-7 fv-row" >
                  <!--begin::Label-->
                  <label class="fs-6 fw-bold mb-2 required">
                    <span>На кого задача</span>
                  </label>
                  <!--end::Label-->
                  <ng-select [items]="managers"
                             bindLabel="name"
                             bindValue="id"
                             formControlName="user_id"
                             [loading]=loadingData
                             loadingText='Loading...'
                             (click)="loadUsersData()">
                  </ng-select>
                  <div *ngIf="errors && user_id?.hasError('required')" class="fv-plugins-message-container invalid-feedback">Поле не заполнено</div>
                </div>
              </div>
              <div class="col">
                <div class="d-flex flex-column mb-7 fv-row" >
                  <!--begin::Label-->
                  <label class="fs-6 fw-bold mb-2 required">
                    <span>Тип задачи</span>
                  </label>
                  <!--end::Label-->
                  <ng-select [items]="settingsData|getClientOption:'job_types'"
                             bindLabel="name"
                             bindValue="id"
                             formControlName="job_type_id">
                  </ng-select>
                  <div *ngIf="errors && job_type_id?.hasError('required')" class="fv-plugins-message-container invalid-feedback">Поле не заполнено</div>
                </div>
              </div>
            </div>

            <div class="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
              <div class="col-md-6 col-lg-6 col-sm-6">
                <div class="fv-row mb-7 fv-plugins-icon-container">
                  <!--begin::Label-->
                  <label class="fs-6 fw-bold mb-2 required">Дата</label>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <input [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="" class="form-control form-control-solid" formControlName="date">
                  <owl-date-time #dt1 [hour12Timer]="false" [pickerType]="'calendar'" [firstDayOfWeek]="1"></owl-date-time>
                  <!--end::Input-->
                  <div *ngIf="errors && date?.hasError('required')" class="fv-plugins-message-container invalid-feedback">Поле не заполнено</div>
                </div>
              </div>
              <div class="col-md-6 col-lg-6 col-sm-6">
                <div class="fv-row mb-7 fv-plugins-icon-container">
                  <!--begin::Label-->
                  <label class="fs-6 fw-bold mb-2">Время</label>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <!--                  <input [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" placeholder="" class="form-control form-control-solid" formControlName="time">-->
                  <!--                  <owl-date-time #dt2 [hour12Timer]="false" [pickerType]="'timer'"></owl-date-time>-->
                  <!--end::Input-->
                  <input type="time" class="form-control form-control-solid" formControlName="time"
                         placeholder="HH:mm:ss" />
                </div>
              </div>
            </div>

<!--            <div class="fv-row mb-7 fv-plugins-icon-container" *ngIf="jobForm.value.result_message !== false">-->
<!--              &lt;!&ndash;begin::Label&ndash;&gt;-->
<!--              <label class="fs-6 fw-bold mb-2">Результат задачи</label>-->
<!--              &lt;!&ndash;end::Label&ndash;&gt;-->
<!--              &lt;!&ndash;begin::Input&ndash;&gt;-->
<!--              <input type="text" class="form-control form-control-solid" placeholder="" formControlName="result_message">-->
<!--              &lt;!&ndash;end::Input&ndash;&gt;-->
<!--              <div class="fv-plugins-message-container invalid-feedback"></div>-->
<!--            </div>-->
            <!--end::Input group-->
          </div>
          <!--end::Scroll-->
        </div>
        <!--end::Modal body-->
        <!--begin::Modal footer-->
        <div class="modal-footer flex-center">
          <!--begin::Button-->
          <button type="reset" id="kt_modal_add_task_new_cancel" class="btn btn-light me-3" data-bs-dismiss="modal">Отменить</button>
          <!--end::Button-->
          <!--begin::Button-->
          <button type="submit" id="kt_modal_add_task_new_submit" class="btn btn-primary"
                  [attr.data-kt-indicator]="loading"
                  [disabled]="buttonsBlock"
                  (click)="addJob()">
            <span class="indicator-label">Сохранить</span>
            <span class="indicator-progress">Пожалуйста подождите...
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
          </button>
          <!--end::Button-->
        </div>
        <!--end::Modal footer-->
        <div></div></form>
      <!--end::Form-->
    </div>
  </div>
</div>
