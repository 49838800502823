import {Component, OnInit} from '@angular/core';
import {TranslationService} from './modules/i18n';
// language list
import {locale as enLang} from './modules/i18n/vocabs/en';
import {locale as ruLang} from './modules/i18n/vocabs/ru';
import {locale as uaLang} from './modules/i18n/vocabs/ua';
import {MessageService} from "primeng/api";
import {environment} from "../environments/environment.prod";
import {LayoutService} from "./_metronic/layout";
import {ILayout} from "./_metronic/layout/core/default-layout.config";

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService]
})
export class AppComponent implements OnInit {
  layoutPage: ILayout;
  constructor(private translationService: TranslationService,
              private layout: LayoutService) {

    this.layoutPage = this.layout.getConfig();
    this.layoutPage.content.width = 'fluid';
    this.layoutPage.aside.minimized = true;
    this.layout.setConfig(this.layoutPage);

    // register translations
    this.translationService.loadTranslations(
      enLang,
      ruLang,
      uaLang
    );
    // this.translationService.setLanguage('ru');
    this.translationService.getSelectedLanguage();

    let version = environment.appVersion;
    let localVersion = localStorage.getItem('appVersion');

    if(version != localVersion){
      this.clearOldLocalStorage();
    }

    localStorage.setItem('appVersion', version);
  }

  ngOnInit() {}

  clearOldLocalStorage():void {
    let keys = Object.keys(localStorage),
      i = keys.length;

    for (let k = 0; k < i; k++){

      if(keys[k] == 'language'){
        continue;
      }

      localStorage.removeItem(keys[k])
    }
  }
}
