import { Pipe, PipeTransform } from '@angular/core';
import {TranslationService} from "../modules/i18n";

@Pipe({
  name: 'getClientOption'
})
export class GetClientOptionPipe implements PipeTransform {

  constructor(private translationService: TranslationService) {}

  transform(settings:any,optionName:string, optionId?:number,className?:boolean): any {
    let lang = this.translationService.getSelectedLanguage()
    if(optionId && className){
      return settings[optionName].find((value:any) => value.id == optionId)?.class;
    }
    if(optionId){
      if(lang==='ru'||lang==='ua'){
        return settings[optionName].find((value:any) => value.id == optionId)?.name;
      }
      if(lang==='en'){
        let option = settings[optionName].find((value:any) => value.id == optionId)
        return option?.name_en||option?.name;
      }
    } else {
      return settings[optionName]?settings[optionName]:[];
    }
  }

}
