import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsReadCommentsPipe } from '../is-read-comments.pipe';
import {GetClientOptionPipe} from "../get-client-option.pipe";
import {GetManagerNamePipe} from "../get-manager-name.pipe";
import { GetUserPicPipe } from '../get-user-pic.pipe';
import {SearchInArrayObjectsPipe} from "../search-in-array-objects.pipe";
import {TimeDifferencePipe} from "../time-difference.pipe";


@NgModule({
  declarations: [GetManagerNamePipe,
    IsReadCommentsPipe,
    GetClientOptionPipe,
    GetUserPicPipe,
    SearchInArrayObjectsPipe,
    TimeDifferencePipe],
  imports: [
    CommonModule
  ],
  exports:[GetManagerNamePipe,
    IsReadCommentsPipe,
    GetClientOptionPipe,
    GetUserPicPipe,
    SearchInArrayObjectsPipe,
    TimeDifferencePipe]
})
export class SharedPipesModule { }
