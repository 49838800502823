<ng-container *ngIf="user$ | async as _user">
  <div class="menu-item px-3" ngbDropdownItem>
    <div class="menu-content d-flex align-items-center px-3">
      <div class="symbol symbol-50px me-5">
        <img alt="User avatar" class="pics-fit" [src]="_user.profile_avatar" />
      </div>

      <div class="d-flex flex-column">
        <div class="fw-bolder d-flex align-items-center fs-5">
          {{ _user.name }}
<!--          <span class="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2" >Pro</span>-->
        </div>
        <a class="fw-bold text-muted text-hover-primary fs-7 cursor-pointer" >
          {{ _user.email }}
        </a>
      </div>
    </div>
  </div>

  <div class="separator my-2"></div>

  <div class="menu-item px-5" ngbDropdownItem >
    <a routerLink="/users/{{_user.id}}/overview" class="menu-link px-5" translate="APP_USER_MENU.PROFILE">
    </a>
  </div>

  <div class="menu-item px-5" ngbDropdownItem >
    <a class="menu-link px-5 cursor-pointer" routerLink="/users/{{_user.id}}/deals">
      <span class="menu-text" translate="APP_USER_MENU.DEALS"></span>
    </a>
  </div>

  <div class="separator my-2" ></div>

  <div
    class="menu-item px-5"
    ngbDropdown
  >
    <a class="menu-link px-5 cursor-pointer" ngbDropdownToggle id="dropdownUserLanguage">
      <span class="menu-title position-relative" translate="APP_USER_MENU.LANGUAGE">
        <span
          class="
            fs-8
            rounded
            bg-light
            px-3
            py-2
            position-absolute
            translate-middle-y
            top-50
            end-0
          "
        >
          {{ language.name }}{{ " " }}
          <img
            class="w-15px h-15px rounded-1 ms-2"
            [src]="language.flag"
            alt="{{language.name}}"
          />
        </span>
      </span>
    </a>

    <div class="menu-sub menu-sub-dropdown w-175px py-4" ngbDropdownMenu aria-labelledby="dropdownUserLanguage">
      <div
        *ngFor="let l of langs"
        class="menu-item px-3 cursor-pointer"
        (click)="selectLanguage(l.lang)"
        ngbDropdownItem
      >
        <a
          class="menu-link d-flex px-5"
          [ngClass]="l.lang === language.lang ? 'active' : ''"
        >
          <span class="symbol symbol-20px me-4">
            <img class="rounded-1" [src]="l.flag" alt="metronic" />
          </span>
          {{ l.name }}
        </a>
      </div>
    </div>
  </div>

  <div class="menu-item px-5" ngbDropdownItem>
    <a (click)="logout()" class="menu-link px-5 cursor-pointer" translate="APP_USER_MENU.LOGOUT"></a>
  </div>
</ng-container>
