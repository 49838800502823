import { Directive, ElementRef, Output, EventEmitter, OnInit } from '@angular/core';

@Directive({
  selector: '[appInView]'
})
export class InViewDirective implements OnInit {

  @Output() inView: EventEmitter<any> = new EventEmitter();

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.inView.emit();
        }
      });
    });

    observer.observe(this.elementRef.nativeElement);
  }
}
