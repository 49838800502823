import {Component, Input, OnInit} from '@angular/core';
import {SettingsService} from "../../../_metronic/layout/core/settings.service";
import {ISettings} from "../../../_metronic/layout/core/default-settings.config";

@Component({
  selector: 'app-view-deals',
  templateUrl: './view-deals.component.html',
  styleUrls: ['./view-deals.component.scss']
})
export class ViewDealsComponent implements OnInit {
  settingsData: ISettings;

  @Input() deals:any = [];

  constructor(private settings: SettingsService) { }

  ngOnInit(): void {
    this.settingsData = this.settings.getSettings();
  }

  getClientOption(optionName:string, optionId?:number){
    const options:any = this.settings.getProp(optionName);
    if(optionId){
      return options.find((value:any) => value.id == optionId).name;
    } else {
      return options;
    }
  }

}
