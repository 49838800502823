import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ViewDealsComponent} from "./view-deals.component";
import {RouterModule} from "@angular/router";
import {SharedPipesModule} from "../../../pipes/shared-pipes/shared-pipes.module";



@NgModule({
  declarations: [ViewDealsComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedPipesModule
  ],
  exports: [
    ViewDealsComponent
  ]
})
export class ViewDealsModule { }
