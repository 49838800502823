import {DefaultLayoutConfig} from "./default-layout.config";

export interface ISettings{
  deal_statuses: [];
  deal_types: [];
  delivery_types: [];
  delivery_ways: [];
  job_types: [];
  pay_types: [];
  pickup_types: [];
  roles: any[];
  user_statuses: [];
}

// export interface IRoles{
//   id:number;
//   name: string;
//   name_en: string;
//   description: string;
// }

export const DefaultSettings: ISettings = {
  deal_statuses: [],
  deal_types: [],
  delivery_types: [],
  delivery_ways: [],
  job_types: [],
  pay_types: [],
  pickup_types: [],
  roles: [
    {
      "id": 1,
      "name": "admin",
      "name_en": "admin",
      "description": "Full access role"
    },
    {
      "id": 2,
      "name": "Директор",
      "name_en": "ceo",
      "description": "Full ceo role"
    },
    {
      "id": 3,
      "name": "Топ менеджер",
      "name_en": "adviser",
      "description": "Full management role"
    },
    {
      "id": 4,
      "name": "Sales Manager",
      "name_en": "manager",
      "description": "Manager role"
    },
    {
      "id": 5,
      "name": "Склад",
      "name_en": "warehouse",
      "description": "Full warehouse role"
    },
    {
      "id": 6,
      "name": "Клиент",
      "name_en": "client",
      "description": "Client role"
    },
    {
      "id": 7,
      "name": "Гость",
      "name_en": "guest",
      "description": "Presentation role"
    },
    {
      "id": 8,
      "name": "Финансист",
      "name_en": "financial",
      "description": "Full financial access"
    },
    {
      "id": 9,
      "name": "Логистика",
      "name_en": "logistic",
      "description": "Logistic manager"
    },
    {
      "id": 10,
      "name": "Manager WH USA-China",
      "name_en": "Manager USA-China",
      "description": "Managers USA warehouse, for China clients"
    },
    {
      "id": 11,
      "name": "Менеджер Украина",
      "name_en": "Manager Ukraine",
      "description": "Manager Ukraine"
    },
    {
      "id": 12,
      "name": "Manager China",
      "name_en": "Manager China",
      "description": "Manager China"
    },
    {
      "id": 13,
      "name": "Операционист",
      "name_en": "Operational Manager",
      "description": "Operational Manager China"
    },
    {
      "id": 14,
      "name": "Sales China agent",
      "name_en": "Sales China agent",
      "description": "Sales China agent"
    },
    {
      "id": 15,
      "name": "Client CHINA_USA",
      "name_en": "Client CHINA_USA",
      "description": "Client CHINA_USA"
    }
  ],
  user_statuses: [],
}
