import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isReadComments'
})
export class IsReadCommentsPipe implements PipeTransform {

  transform(comments: any): boolean {
    let is_read_list: number = 0;

    Object.values(comments).map((comment: any) => {
      is_read_list += comment.is_read === 1 ? 0 : 1;
    })
    return is_read_list > 0;
  }
}
