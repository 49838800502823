import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../../core/layout.service';
import {environment} from "../../../../../environments/environment";
import {Subscription} from "rxjs";
import * as moment from 'moment';

const APP_VERSION = `${environment.appVersion}`;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  footerContainerCssClasses: string = '';
  currentDateStr: string = new Date().getFullYear().toString();
  appVersion = APP_VERSION;
  timeUkraine: any;
  timeEurope: any;
  timeUSA: any;
  timeChina: any;
  intervalId: any;
  subscription: Subscription;

  constructor(private layout: LayoutService) {}

  ngOnInit(): void {
    this.footerContainerCssClasses =
      this.layout.getStringCSSClasses('footerContainer');

    this.getLocalesTime();
  }

  getLocalesTime(){
    this.intervalId = setInterval(() => {
      let dateUSA = new Date().toLocaleString("ru-RU", {timeZone: "America/New_York", second: undefined});
      let dateEU = new Date().toLocaleString("ru-RU", {timeZone: "Europe/Vienna"});
      let dateUA = new Date().toLocaleString("ru-RU", {timeZone: "Europe/Kiev"});
      let dateChina = new Date().toLocaleString("ru-RU", {timeZone: "Asia/Hong_Kong"});

      this.timeUSA = dateUSA;
      this.timeEurope = dateEU;
      this.timeUkraine = dateUA;
      this.timeChina = dateChina;
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
